import {
    BuyerReferenceType,
    CampaignErrorableType,
    CampaignErrorType,
    CampaignStatus,
    Displays_LineItemLineItemType,
    EsPCAReportField,
    EsPCAReportFilterField,
    PCAReportField,
    PCAReportMetric,
    UserType,
} from 'c-sdk';
import { ChartLabelAnchor } from 'c-reports/Components/ReportControlsWrapper/types';

const en = {
    CommonWords: {
        Impacts: 'Impacts',
        Pack: 'Pack',
        pack: 'pack',
        Frame: 'Frame',
        frame: 'frame',
        TVRegion: 'TV Region',
        Region: 'Region',
        Conurbation: 'Conurbation',
        Town: 'Town',
        MediaOwner: 'Media Owner',
        MediaOwners: 'Media Owners',
        owner: 'owner',
        owners: 'owners',
        Owners: 'Owners',
        Owner: 'Owner',
    },
    Navigation: {
        // temp while dashboard and campaign screen are the same
        // dashboardModuleLabel: 'Dashboard',
        dashboardModuleLabel: 'Campaigns',
        logoutLabel: 'Logout',
        campaigns: 'Campaigns',
        agencies: 'Agencies',
        buyers: 'Buyers',
        clients: 'Brands',
        owners: '$t(CommonWords.Owners)',
        creatives: 'Creatives',
        dataStatus: 'Data Status',
        admin: 'Admin',
        adminSkins: 'Skins',
        adminUsers: 'Users',
        adminRoles: 'User Roles',
        adminMarkets: 'Markets',
        adminImpersonateUser: 'Impersonate User',
        adminStopImpersonateUser: 'Stop Impersonating',
        adminFrameSearch: '$t(CommonWords.Frame) Search',

        popDisplaysAdmin: 'Pop Displays',
        popDisplaysTaskQueue: 'Task Queue',
        popDisplaysLogFileImports: 'Log Imports',
        popDisplaysLogImportSource: 'Log Import Sources',
        popDisplayPlayoutExport: 'Playout Export',
        popDisplaysDisplays: 'Display Explorer',
        popDisplaysChannelFrames: 'Channel Explorer',
        popDisplaysVirtualCampaignBuilder: 'Campaign Builder',
        popDisplaysScheduleTemplates: 'Schedule Templates',

        displaysAdmin: 'Admin Displays',
        displaysDisplayList: 'Displays',
        displaysOwnerList: '$t(CommonWords.Owners)',
        displaysPacks: '$t(CommonWords.Pack)s',
        displaysAnalogue: 'Analogues',
        displaysTag: 'Tag',
        displaysCountry: 'Countries',
        displaysMarket: 'Markets',
    },
    Pages: {
        DashboardHome: {
            metaTitle: 'Dashboard',
            metaTitle1: 'Dashboard Sub 1',
            metaTitle2: 'Dashboard Sub 2',
            metaTitleExample: 'Another Page',
        },
        Login: {
            metaTitle: 'Login',
            welcomeMessage:
                'Welcome to our new and improved UniLIVE platform! Please use your existing login credentials. We hope you enjoy the enhanced experience!',
            genericLoginError: 'Login Failed',
            emailInputLabel: 'Email Address',
            passwordInputLabel: 'Password',
            forgotPasswordLinkLabel: 'Forgot password?',
            signInButtonLabel: 'Log in',
            passwordResetSuccess: 'Your password has been updated successfully.',
            chooseUserType: 'Choose a user type to login as',
            userTypes: {
                [UserType.Admin]: 'Admin',
                [UserType.Agency]: 'Agency',
                [UserType.Buyer]: 'Buyer',
                [UserType.Client]: 'Brand',
                [UserType.Owner]: '$t(CommonWords.Owner)',
            },
        },
        revalidate: {
            metaTitle: 'Revalidating your account...',
            successMessage: 'Your account has been revalidated. You will be redirected shortly.',
        },
        MyProfile: {
            title: 'My Profile',
            changePassword: 'Change Password',
            changePasswordDescription:
                'You will be logged out once your password has been changed.',
        },
        ForgotPasswordRequest: {
            emailInputLabel: 'Email',
            emailInputPlaceholder: "Enter your account's email address",
            submitLabel: 'Send me a reset link',
            invalidEmail: 'Please enter a valid email address.',
            metaTitle: 'Request Password Reset',
            title: 'Password Reset',
            subTitle: 'You will receive a password reset link by submitting your email below.',
        },
        ForgotPasswordCode: {
            tokenInputLabel: 'Reset Code',
            tokenInputPlaceholder: 'Enter the reset code',
            invalidEmail: 'Please enter a valid email address.',
            emailInputLabel: 'Email',
            emailInputPlaceholder: "Enter your account's email address",
            passwordInputLabel: 'Password',
            passwordInputPlaceholder: 'Enter your new password',
            passwordConfirmationInputLabel: 'Confirm Password',
            passwordConfirmationInputPlaceholder: 'Confirm your new password',
            submitLabel: 'Set my new password',
            resetEmailSuccess: 'An email containing the reset link has been sent to your inbox.',
            metaTitle: 'Reset Password',
            title: 'Password Reset',
        },
        CompleteRegistration: {
            metaTitle: 'Complete Registration',
            title: 'Complete Registration',
            submitLabel: 'Complete Registration',
        },
        AcceptInvite: {
            inviteLandingMetaTitle: 'Campaign Invite',
            inviteDescription: "You've been invited to the campaign {{name}}",

            inviteNoAccount: "Don't have an account?",
            inviteLogin: 'I already have an account',
            inviteRegister: 'Register here',

            emailInputLabel: 'Email',
            metaTitle: 'Complete Registration',
            registerFirstName: 'First Name',
            registerLastName: 'Last Name',
            passwordInputLabel: 'Password',
            passwordConfirmInputLabel: 'Confirm Password',
            registerSubmit: 'Register',

            registerSuccess:
                'An account has been successfully created.\n\nPlease verify your email address by following the instructions in the email we have sent you.',
        },
        VerifyEmail: {
            metaTitle: 'Verify Email',
            loginLabel: 'Go to Login',
            verifySuccess:
                'Your email has been successfully verified. You can now log in by following the link below.',
        },
    },
    Modules: {
        Admin: {
            Home: {
                metaTitle: 'Admin Panel',
                description: 'A collection of configuration screens to control the system.',
            },
            SkinSwitcher: {
                defaultThemeOptionLabel: 'Default Skin',
                dropdownLabel: 'Choose Skin',
            },
            ColorCorrectionSwitcher: {
                label: 'Color Correction',
                none: 'None',
                protanope: 'Protanope',
                deuteranope: 'Deuteranope',
                tritanope: 'Tritanope',
            },
            SkinsList: {
                metaTitle: 'Manage Skins',
                description: 'Manage system skins in real time and assign them to users.',
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                },
                create: {
                    nameLabel: 'New Skin Name',
                    newSkinButton: 'New Skin',
                    submitButton: 'Save New Skin',
                },
            },
            SkinEdit: {
                metaTitle: 'Edit Skin',
                description: "Update an individual skin's configuration.",
                pageTitle: 'Update {{name}}',
                form: {
                    overviewTitle: 'Overview',
                    largeLogoUploadLabel: 'Upload Large Logo',
                    smallLogoUploadLabel: 'Upload Small Logo',

                    assignTitle: 'Assign To Users',
                    assignSkin: 'Set Skin Assignment',

                    agencyAssignLabel: 'Agencies',
                    buyerAssignLabel: 'Buyers',
                    clientAssignLabel: 'Clients',
                    ownerAssignLabel: '$t(CommonWords.Owners)',
                    userAssignLabel: 'Users',

                    colorsTitle: 'Colours',
                    primaryColorLabel: 'Primary Colour',
                    secondaryColorLabel: 'Secondary Colour',
                    dividerColorLabel: 'Divider Colour',
                    cardColorLabel: 'Card Background Colour',
                    bgColorLabel: 'Background Colour',
                    navBgColorLabel: 'Navigation Background Colour',
                    primaryTextColorLabel: 'Primary Text Colour',
                    secondaryTextColorLabel: 'Secondary Text Colour',
                    disabledTextColorLabel: 'Disabled Text Colour',

                    formTabTitle: 'Forms',
                    typographyTabTitle: 'Typography',

                    save: 'Save Skin',
                },
            },
            RolesList: {
                metaTitle: 'Manage Roles',
                description: 'Manage system roles and control user access.',
                create: {
                    nameLabel: 'New Role Name',
                    newRoleButton: 'New Role',
                    submitButton: 'Save New Role',
                },
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                },
            },
            RoleEdit: {
                metaTitle: 'Edit Role',
                description: 'Update a role.',
                permissionTab: 'Permissions',
                usersTab: 'Users',
                pageTitle: 'Update {{name}}',
                generalPermissionsHeader: 'General Permissions',
                entityPermissionsHeader: 'Entity Permissions',
                saveLabel: 'Update Role Settings',
                selectAllPermissions: 'Select All',
                saveDialogTitle: 'Save Role',
                nameInputLabel: 'Name',
                agencyCheckboxLabel: 'Agency Invite - Campaign Shareable link',
                agencyCheckboxExplanation:
                    'When a user is invited to a campaign via the shareable link, this role will be automatically assigned to them when they register or accept the invitation.',
                permissionChangedTitle: 'Permission Changes',
                permissionsAdded: '{{count}} Added',
                permissionsRemoved: '{{count}} Removed',
                nameChangedWarning: 'Name changed from "{{from}}" to "{{to}}"',
                campaignAgencyInviteChangedWarning:
                    'Campaign Agency Invite changed from "{{from}}" to "{{to}}"',
            },
            UsersList: {
                metaTitle: 'Manage Users',
                description: 'Manage system users and control access.',
                createUser: 'New User',
            },
            UserCreate: {
                metaTitle: 'Create User',
                description: 'Add a new user to the system.',
            },
            UserForm: {
                firstName: 'First Name',
                lastName: 'Last Name',
                language: 'Language',
                email: 'Email Address',
                active: 'Active',
                assignAssociations: 'Associations',
                associationsDescription:
                    'Assigning users to Buyers, Agencies, Brands, $t(CommonWords.MediaOwners) and Roles will determine what information the user has access to and what actions they can perform on the system.',
                buyers: 'Buyers',
                agencies: 'Agencies',
                owners: '$t(CommonWords.MediaOwners)',
                brands: 'Brands',

                roles: 'Roles',
                roleDescription:
                    'A user can be assigned multiple roles. When multiple roles are assigned to a user, all of their roles will be combined into a single set of permissions.',

                oldPortalDisabled: 'Disable Old Portal Access',
                profilingEnabled: 'Profiling Enabled',

                failedToSave: 'Failed to save',
                saveSuccess: 'User Updated',
                saveBtn: 'Save',

                failedToCreate: 'Failed to create new user',
                createSuccess: 'User created',
                createBtn: 'Create New User',
            },
            UserEdit: {
                metaTitle: 'Edit User',
                description: 'Update user details.',
                pageTitle: 'Update {{name}}',
                impersonateUserHeader: 'Impersonate {{name}}',

                resendInvitationDescription:
                    'This user has not completed the registration verification process.',
                resendInvitationBtn: 'Resend Invite',

                detailsHeader: 'Details',
                legacyAssociationsHeader: 'Legacy Associations',
                legacyAssociationsDescription: 'Associations set in the old system.',
                legacyBuyerHeader: 'Buyer',
                legacyAgencyHeader: 'Agency',
                legacyBrandHeader: 'Brand',
                legacyOwnerHeader: '$t(CommonWords.MediaOwner)',

                userDetailsTab: 'User Details',
                passwordTab: 'Update Password',

                passwordPageTitle: 'Update {{name}} Password',
                oldPassword: 'Current Password',
                newPassword: 'New Password',
                confirmNewPassword: 'Confirm New Password',
                savePassword: 'Save New Password',

                passwordSaveSuccess: 'Saved new password',
                passwordSaveFail: 'Failed to save new password',
            },
            ImpersonateUser: {
                metaTitle: 'Impersonate User',
                noimpersonate: 'Unable to impersonate this user.',
                description:
                    'Provide system support by impersonating a user. You will be able to see the system as they do.',
                pageHelpDescription:
                    'Choose a user to impersonate below. When impersonating the user you will see the system the way they do.\n\nYou will have the same permissions, access to campaigns and other data. There will be a button at the top of the screen which will allow you to return to your user.',
                userDropdownLabel: 'Search for User',
                startImpersonateButton: 'Start Impersonating as {{type}}',
                genericFailureMessage: 'Failed to impersonate user',
                impersonateSuccess:
                    'Started impersonation successfully. Switching over to user now. This may take a few seconds.',
                stopImpersonateSuccess:
                    'Stopped impersonation. Switching back now. This may take a few seconds.',
                isAlreadyImpersonatingError:
                    'You are already impersonating. You must stop impersonating the current user before starting a new impersonation.',

                chooseUserType:
                    'This user has multiple types of associations. Choose a user type to impersonate as',
            },
            MarketList: {
                metaTitle: 'Markets',
                description: 'Manage markets',
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                },
                create: {
                    newMarketButton: 'New Market',
                    submitButton: 'Create New Market',
                    nameLabel: 'Name',
                },
                update: {
                    updateTitle: 'Update "{{name}}"',
                    nameLabel: 'Name',
                },
            },
            FrameSearch: {
                metaTitle: '$t(CommonWords.Frame) Search',
                description: '$t(CommonWords.Frame) Search',
                frameCount: '{{count}} unique $t(CommonWords.frame)s',
                searchLabel: 'Search',

                frameExportReport: '$t(CommonWords.Frame) Export Report',
                conflictedFramesHeader: '$t(CommonWords.Frame)s for {{frame}}',
                uniqueResolutions: 'Unique Resolutions',
                copyFrameIds: 'Copy $t(CommonWords.Frame) Ids',

                missingFrames: 'Could not find {{count}} $t(CommonWords.frame)s: {{frames}}',
                conflictedFrames: 'The $t(CommonWords.frame) {{frame}} has a conflict',
                active: 'Active',
                inactive: 'Inactive',
                na: 'Not Found',
                yes: 'Yes',
                no: 'No',
                table: {
                    uploadedID: 'Uploaded ID',
                    frameIdCol: '$t(CommonWords.Frame) ID Added',
                    active: 'Active',
                    name: 'ScreenName',
                    idSource: 'Display ID Source',
                    id: 'Display ID Added',
                    lineItemName: 'Line Item Name',
                    displayNameCol: 'Display Name',
                    resolutionCol: 'Resolution',
                    mediaOwnerCol: '$t(CommonWords.MediaOwner)',
                    packNameCol: '$t(CommonWords.Pack) Name',
                    conflict: 'Conflict',
                },
            },
        },
        Main: {
            Agency: {
                List: {
                    metaTitle: 'Agencies',
                    listTitle: 'Search Agencies',
                    description: 'Search and filter agencies',
                    newAgencyButton: 'New Agency',
                    newAgencySubmit: 'Create new Agency',
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        campaignCount: 'Campaign Count',
                        marketCol: 'Market',
                    },
                    update: {
                        title: 'Update {{name}}',
                        nameLabel: 'Name',
                        marketLabel: 'Market',
                    },
                },
                Overview: {
                    Tabs: {
                        campaigns: 'Campaigns',
                        creative: 'Creatives',
                        creativeWarningsTooltip: 'Contains Creative Warnings',
                        users: 'Users',
                    },
                },
            },
            Buyer: {
                audienceSourceLabel: 'Audience Source',
                save: 'Save',
                List: {
                    metaTitle: 'Buyers',
                    listTitle: 'Search Buyers',
                    description: 'Search and filter buyers',
                    newBuyerButton: 'New Buyer',
                    newBuyerSubmit: 'Create new Buyer',
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        campaignCount: 'Campaign Count',
                        marketCol: 'Market',
                    },
                    create: {
                        nameLabel: 'Name',
                        marketLabel: 'Market',
                    },
                },
                Overview: {
                    Tabs: {
                        campaigns: 'Campaigns',
                        costs: 'Costs',
                        users: 'Users',
                    },
                    Costs: {
                        costCol: 'Cost Per Unit',
                        daysCol: 'Days Per Unit',
                        maxCostCol: 'Max Cost',
                        createdCol: 'Created At',

                        explanation:
                            'Per buyer cost configuration enables the system to work out the cost of a campaign depending on the number of line items, and the duration (in days) each line item will be used.',
                        newCostButton: 'Create New Cost',

                        newCostDialogTitle: 'Create New Buyer Cost',
                        newCostDays: 'Days Per Unit',
                        newCost: 'Cost Per Unit',
                        newMaxCost: 'Max Campaign Cost',
                    },
                    EditDialog: {
                        title: 'Edit Buyer',
                        nameLabel: 'Name',
                        marketLabel: 'Market',
                        refPrefixLabel: 'Campaign Ref Prefix',
                        refTypeLabel: 'Campaign Ref Types',
                        refMinLengthLabel: 'Campaign Ref Min Length',
                        refMaxLengthLabel: 'Campaign Ref Max Length',
                        refPreviewLabel: 'Campaign Ref Input Preview',
                        refTypes: {
                            [BuyerReferenceType.Any]: 'Any',
                            [BuyerReferenceType.Alpha]: 'Alpha',
                            [BuyerReferenceType.Alphanumeric]: 'Alphanumeric',
                            [BuyerReferenceType.Numeric]: 'Numeric',
                        },
                    },
                },
            },
            Client: {
                List: {
                    metaTitle: 'Brands',
                    description: 'Brand List',
                    listTitle: 'Search Brands',
                    newClientButton: 'New Brand',
                    newClientSubmit: 'Create new Brand',
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        marketCol: 'Market',
                    },
                    update: {
                        title: 'Update {{name}}',
                        nameLabel: 'Name',
                        marketLabel: 'Market',
                    },
                },
            },
            Owner: {
                List: {
                    metaTitle: '$t(CommonWords.Owners)',
                    listTitle: 'Search $t(CommonWords.Owners)',
                    description: 'Search and filter $t(CommonWords.owners)',
                    newOwnerButton: 'New $t(CommonWords.Owner)',
                    newOwnerSubmit: 'Create new $t(CommonWords.Owner)',
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        marketCol: 'Market',
                    },
                    update: {
                        title: 'Update {{name}}',
                        nameLabel: 'Name',
                        marketLabel: 'Market',
                    },
                },
                Overview: {
                    Tabs: {
                        users: 'Users',
                    },
                },
            },
            Campaigns: {
                Invite: {
                    metaTitle: 'Campaign Invite',
                    inviteDescription:
                        "You've been invited to the campaign {{name}}, do you accept?",
                    declineLabel: 'Decline',
                    acceptLabel: 'Accept',
                    inviteNotAllowed:
                        'You are trying to access the creative agency upload section for campaign "{{campaign_name}}". You cannot accept this invitation as you have an association with {{association}}, please contact support@uniledsoftware.com if you feel you need to access this section of the campaign',
                },
                Ai: {
                    ButtonLabel: 'AI Assistant (Beta)',
                    title: 'Campaign - AI Assistant (Beta)',
                    messagePlaceholder: 'Type a message...',
                    accuracyMessage:
                        'This assistant can make mistakes, double check important information.',
                    aiNoMessages: 'send a message to start a chat',
                    restartChat: 'Restart Chat',
                },
                List: {
                    metaTitle: 'Campaigns',
                    listTitle: 'Search Campaigns',
                    description: 'Search and filter campaigns',
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        referenceCol: 'Reference',
                        statusCol: 'Status',
                        brandCol: 'Brand',
                        buyerCol: 'Buyer',
                        displayCountCol: 'Displays',
                        dateRangeCol: 'Dates',
                        startDateCol: 'Start Date',
                        endDateCol: 'End Date',
                        reportingCol: 'Reporting',

                        playoutByMediaOwnerTooltip: 'By $t(CommonWords.MediaOwner):',
                    },
                    createCampaign: 'New Campaign',
                    scopes: {
                        upcoming: 'Upcoming',
                        live: 'Live',
                        past: 'Past',
                        archived: 'Archived',
                        hasErrors: 'Has Creative Errors',
                        awaitingData: 'Missing Data',
                        inspectingData: 'Data Query',
                        awaitingBookingPlan: 'Missing Booking Data',
                        inflightReportSent: 'Inflight Report Sent',
                        pcaReportSent: 'CAD Report Sent',
                    },
                    statusDropdownLabel: 'Status',
                    startDateDropdownLabel: 'Start Date',
                    endDateDropdownLabel: 'End Date',
                    mediaOwnersDropdownLabel: '$t(CommonWords.MediaOwners)',
                    reportingPercentageLabel: 'Reporting Percentage',
                    bookingSummarySignOffLabel: 'Booking Summary',
                    signOffLabel: 'Sign Off',
                    signedOffOptionLabel: 'Signed Off',
                    notSignedOffOptionLabel: 'Not Signed Off',
                    highPriorityLabel: 'High Importance',
                    highPriorityOptionLabel: 'High Importance',
                    notHighPriorityOptionLabel: 'Not High Importance',
                },
                Create: {
                    currentStepLabel: 'Step {{current}}/{{total}}',
                    stepDetailsTitle: 'New Campaign Details',
                    stepLineItemsTitle: 'New Campaign Displays',
                    stepScheduleTitle: 'New Campaign Scheduling',
                    stepSummaryTitle: 'New Campaign Summary',
                    isVistar: 'Vistar',

                    metaTitle: 'Create New Campaign',
                    description: 'Create a new campaign',

                    detailsStepLabel: 'Details',
                    chooseDisplaysStepLabel: 'Choose Displays',
                    scheduleStepLabel: 'Scheduling',
                    summaryStepLabel: 'Summary',

                    nextStepLabel: 'Next',
                    prevStepLabel: 'Go Back',
                    createCampaignLabel: 'Create Campaign',

                    LineItemsTable: {
                        nameHeader: 'Name',
                        ownerHeader: '$t(CommonWords.MediaOwner)',
                        typeHeader: 'Type',
                        environmentHeader: 'Environment',
                        sizeHeader: 'Format',
                        resolutionHeader: 'Resolution (W x H)',
                        popshotsHeader: 'Images Uploaded',
                        assignedCreativesHeader: 'Assigned Creatives',
                        creativesAvailableHeader: 'Creatives Available',
                        fileTypesHeader: 'File Types',
                        aspectRatioHeader: 'Aspect Ratio',
                        resultsSelected: '{{count}} Selected',
                        lineItemSelected: 'Selected',
                        addLineItem: 'Add',
                        addedLineItem: 'Added',
                        startDateHeader: 'Start Date',
                        endDateHeader: 'End Date',
                        actionsHeader: 'Actions',
                        daysHeader: 'Total Live Days',
                        costHeader: 'Cost',

                        popShotsNoImages: '[no image has been uploaded]',
                        groupFilterLabel: 'Group',

                        assignCreatives: {
                            autoAssign: 'Auto Assign',
                            autoAssignFailed: 'Auto Assign Failed',
                            massUnAssign: 'Auto UnAssign',
                            massUnAssignFailed: 'Auto UnAssign Failed',

                            manualAssign: 'Assign Creatives',

                            dialogDescription:
                                'Please choose which creatives to assign to this display to by checking the checkboxes below',
                            assignSubmitBtn: 'assign selected',

                            failedToLoadAssignData: 'Failed to load',
                            failedToLoadAssignDataRetry: 'Reload',

                            failedToAssign:
                                'An error has occurred while trying to assign creatives to the display',

                            unAssignDialogDescription:
                                'Please choose which creatives to unassign from this display to by checking the checkboxes below',
                            unAssignSubmitBtn: 'unassign selected',

                            searchLabel: 'Search',
                            durationLabel: 'Duration',
                            resolutionDropdownLabel: 'Resolution (WxH)',
                            groupDropdownLabel: 'Group',
                            typeDropdownLabel: 'Type',
                            ratioDropdownLabel: 'Ratio',
                        },
                    },

                    bulkAttachMissingMarket:
                        'The Buyer you have selected is not assigned to a market.',
                    bulkAttachTitle: 'Bulk Attach $t(CommonWords.Frame)s',
                    bulkAttachScreenInfo: 'This is for digital screens only',
                    bulkAttachDescription:
                        'Paste a list of $t(CommonWords.frame) IDs (separated by new lines) below and we will automatically add them to the campaign.',
                    bulkAttachFrameCount: '{{count}} unique $t(CommonWords.frame)s',
                    bulkAttachFramesSubmit: 'Add $t(CommonWords.frame)s',
                    bulkAttachFramesSelect: 'Add Displays',
                    bulkAttachFramesInvalidError: 'Failed to find {{count}} $t(CommonWords.frame)s',
                    bulkAttachFramesError: 'Failed to find displays',
                    bulkAttachButtonLabel: '$t(CommonWords.Frame) List',

                    bulkAttachConflictDescription:
                        'Displays containing the same $t(CommonWords.Frame) ID have been found.\n\nPlease choose which display to use for each $t(CommonWords.Frame) ID.',
                    bulkAttachConflictFrameLabel: '$t(CommonWords.Frame)',

                    LineItemsSummary: {
                        allOwnersHeader: 'All Line Items',
                        noOwnersHeader: 'No $t(CommonWords.MediaOwner)',
                    },

                    campaignNameLabel: 'Campaign Name',
                    referenceLabel: 'Reference',
                    buyerDropdownLabel: 'Buyer',
                    clientDropdownLabel: 'Brand',
                    additionalContactsDropdownLabel: 'Campaign Contacts',
                    agencyAssignedDropdownLabel: 'Agency',

                    Costing: {
                        daysInputPlaceholder: '{{count}} days',
                        total: 'Total:',
                        lineItemCount: '{{count}} line items',
                        applyAllDays: 'Apply',
                        applyAllPlaceholder: 'Days',
                    },

                    Summary: {
                        campaignName: 'Campaign Name',
                        campaignRef: 'Ref',
                        campaignDates: 'Dates',
                        campaignDatesValue: '{{start}} to {{end}} ({{days}} days)',
                        campaignAgency: 'Agency',
                        campaignClient: 'Brand',
                        campaignBuyer: 'Buyer',
                    },

                    creating: 'Creating Campaign',
                    failedToCreate: 'Failed to create campaign.',
                },
                CloneCampaign: {
                    metaTitle: 'Clone Campaign',
                    cloneBreadcrumb: 'Clone',
                },
                EditLineItems: {
                    metaTitle: 'Update Campaign Displays',
                    editLineItemsBreadcrumb: 'Update Displays',
                    editLink: 'Update Campaign',
                    cloneCampaign: 'Clone Campaign',

                    detailsStepLabel: 'Dates',
                    chooseDisplaysStepLabel: 'Update Displays',
                    scheduleStepLabel: 'Scheduling',
                    summaryStepLabel: 'Summary',

                    stepDetailsTitle: 'Update Dates',
                    stepChooseDisplaysTitle: 'Choose Displays',
                    stepScheduleTitle: 'Scheduling',
                    stepSummaryTitle: 'Summary',

                    prevStepLabel: 'Back',
                    nextStepLabel: 'Next',
                    saveCampaignLabel: 'Save',

                    lineItemsAdded: '{{count}} Displays Added',
                    lineItemsRemoved: '{{count}} Displays Removed',

                    saving: 'Saving Campaign',
                    failedToSave: 'Failed To Save',
                },
                Overview: {
                    metaTitle: 'Campaign Summary',
                    pageTitle: 'Campaign Summary',
                    description: 'View and update campaign specific information.',
                    popImagesComingSoon: 'Pop images are on their way',
                    pcaDataComingSoon: "Your data is on it's way",
                    pcaDataComingSoonBtnLabel: 'Back to campaign overview',
                    underConstructionWarning:
                        'The screens are being added to your campaign and will be ready soon',
                    campaignArchivedPageStatus: 'This campaign has been archived',
                    missingDisplay: {
                        title: 'Missing Displays',
                        mediaNameTitle: 'Name',
                        frameIdTitle: '$t(CommonWords.Frame) ID',
                        mediaOwnerTitle: '$t(CommonWords.MediaOwner)',
                        screenNameSummaryTitle: 'Screen Name Summary',
                        environmentTitle: 'Environment',
                        formatTitle: 'Format',
                        lineSpaceItemTitle: 'Line Space Item',
                    },
                    OverviewSection: {
                        bookedByHeader: 'Booked by',
                        brandHeader: 'Brand',
                        buyerHeader: 'Buyer',
                        duration: 'Duration',
                        agencyHeader: 'Media Agency',
                        isDsP: 'DSP',
                        isVistar: 'Vistar',
                        client: 'Client',
                        internal: 'Internal',
                        unlinked: 'Unlinked CF Report',
                    },
                    EditDialog: {
                        title: 'Edit Campaign',
                        campaignNameLabel: 'Campaign Name',
                        referenceLabel: 'Reference',
                        agencyDropdownLabel: '{{name}} ({{campaignsCount}} campaigns)',
                    },
                    ShareDialog: {
                        shareCopied: 'Copied!',
                        sharedWithUsers: 'Shared With ({{count}})',
                    },
                    ActionLabels: {
                        edit: 'Edit',
                        share: 'Share',
                        archive: 'Archive',
                        unarchive: 'Unarchive',
                        clone: 'Clone',
                        downloadSpec: 'Spec',
                        uploadArtwork: 'Upload Artwork',
                        downloadCreatives: 'Creatives',
                        validateCreatives: 'Validate Creatives',
                        bookingSummary: 'Booking Summaries',
                        campaignSchedule: 'Campaign Schedule',
                        cad: 'CAD',
                        pca: 'PCA',
                        ioIds: `IO ID's`,
                        apply: 'Apply',
                    },
                    Pca: {
                        title: 'PCA',
                        submit: 'Submit',
                        success: 'PCA power point will be emailed to you shortly',
                    },
                    Cad: {
                        title: 'CAD',
                        cadType: 'Upload Or Download',
                        upload: 'Upload',
                        download: 'Download',
                        pleaseSelect: 'Please Select...',
                        Download: 'Download',
                        template: 'Generate template CSV',
                        environment: 'Environment',
                        downloadSuccess:
                            'We have sent you an email to download the CAD file. This can take up to 30 minutes',
                        client: 'Client',
                        internal: 'Internal',
                        lastRunAt: 'Last Run At',
                        never: 'Never',
                        runSuccess: 'Pipeline task queued',
                        deleteSuccess: 'Pipeline task queued',
                        publishSuccess: 'Pipeline task queued',
                        run: 'Run',
                        delete: 'Delete',
                        publish: 'Publish',
                    },
                    Tabs: {
                        displays: 'Displays',
                        popImages: 'Pop Images',
                        images: 'Pop Images',
                        creatives: 'Creatives',
                        reporting: 'Reporting',
                        cost: 'Costs',
                        analyticsSummaryReports: 'Summary',
                        playsReports: 'Plays',
                        impactsReports: '$t(CommonWords.Impacts)',
                        timeReports: 'Time Played',
                        dsp: 'DSP',
                        energyUsage: 'Energy Usage',
                        displayVerification: 'Display Verification',
                    },
                    dsp: {
                        geopath: 'Geopath Audited',
                        vistar: 'Non Geopath Audited',
                        audited: 'Route Audited Frames',
                        nonAudited: 'Non Route Audited Frames',
                        combinedKeyCol: '$t(CommonWords.MediaOwner) | Environment | Format',
                        mediaOwnerCol: '$t(CommonWords.MediaOwner)',
                        environmentCol: 'Environment',
                        formatCol: 'Format',
                        TTDPlaysCol: 'TTD Plays',
                        VIOOHPlaysCol: 'VIOOH Plays',
                        sumOfVioohImpressions: 'Sum of VIOOH Impressions',
                        sumOfTTDImpressions: 'Sum of TTD Impressions',
                        sumOfRouteDeliveredImpacts: 'Sum of Route Delivered Impacts',
                        count: 'Count',
                        value: 'Value',
                        top: 'Greater Value',
                        bottom: 'Less Value',
                        all: 'All',
                        uploadFile: 'Click  or drag and drop to upload a file',
                        displayOwner: 'Display Owner',
                        displayFormat: 'Display Format',
                        plays: '# Plays',
                        dspImpressions: 'DSP Impressions',
                        dspIm: 'Average DSP IM',
                        geopathIm: 'Average Geopath IM',
                        geopathImpressions: 'Geopath Impressions',
                        averageFormatIm: 'Average Format IM',
                        var: '% VAR',
                        imVar: 'IM VAR',
                        var2: 'VAR',
                    },
                    Displays: {
                        displayCountHeader: 'All Displays ({{count}})',
                    },
                    BookingSummary: {
                        title: 'Campaign Booking Summaries',
                        dropzoneText: 'Drag & Drop File or Click Here to Upload',
                        save: 'Save Notes',
                    },
                    Reports: {
                        overallTabTitle: 'Overall',
                        byFieldButton: 'Group by',
                        byMap: 'Map view',
                        viewBy: 'View by',
                        filterBy: 'Filter by',
                        dataStatusDropdown: 'Inspect',
                        errorTitle: 'Oh no!',
                        errorDescription: 'Some fatal error occured',
                        refresh: 'Refresh',
                        stickers: {
                            today: 'Today',
                            week: 'Week',
                        },
                        frameID: '$t(CommonWords.Frame) ID',
                        campaignDates: 'Campaign Dates',
                        pageDates: 'Page Dates',
                        exportPca: {
                            exportPcaTitle: 'Export Reporting Data',
                            exportPcaDescription:
                                'An email will be sent containing links to the data files.',
                            exportPcaDescription2:
                                'Please allow up to 30 minutes for the email to arrive.',
                            exportPcaBtnLabel: 'Export',
                            exportPcaBtnLabelLoading: 'Loading',
                            exportPcaBtnLabelClose: 'Close',
                            exportPcaBtnLabelFailed: 'Try Again',

                            exportSuccess: 'Success',
                            exportFailed:
                                'Failed to export. Please try again or contact an administrator.',
                        },

                        [PCAReportMetric.Plays]: {
                            expectedLabel: 'Expected Plays',
                            actualLabel: 'Total Plays',
                            percentageLabel: 'Plays Percentage',
                            performanceLabel: 'Plays Performance',
                            missingLabel: 'Missing Plays',

                            overallCompareExpectedVsActualTitle: 'Expected vs Total Plays',
                            overallCompareByPerformanceTitle: 'By Performance %',

                            overallTabTotalTabExpectedTitle: 'Target Plays',
                            overallTabTotalTabActualTitle: 'Total Plays',
                            overallTabTotalValueLabel: '{{count}} Plays',
                            overallTabTotalPerformance: 'Plays',
                            overallTabTotalPerformanceTime: 'Time',

                            mapSettingActualHeat: 'Plays',
                            mapSettingExpectedHeat: 'Expected Plays',
                        },
                        [PCAReportMetric.Impacts]: {
                            expectedLabel: 'Expected $t(CommonWords.Impacts)',
                            actualLabel: 'Total $t(CommonWords.Impacts)',
                            percentageLabel: '$t(CommonWords.Impacts) Percentage',
                            performanceLabel: '$t(CommonWords.Impacts) Performance',
                            missingLabel: 'Missing $t(CommonWords.Impacts)',

                            overallCompareExpectedVsActualTitle:
                                'Expected vs Total $t(CommonWords.Impacts)',
                            overallCompareByPerformanceTitle: 'By Performance %',

                            overallTabTotalTabExpectedTitle: 'Target $t(CommonWords.Impacts)',
                            overallTabTotalTabActualTitle: 'Total $t(CommonWords.Impacts)',
                            overallTabTotalValueLabel: '{{count}} $t(CommonWords.Impacts)',
                            overallTabTotalPerformance: '$t(CommonWords.Impacts)',

                            mapSettingActualHeat: '$t(CommonWords.Impacts)',
                            mapSettingExpectedHeat: 'Expected $t(CommonWords.Impacts)',
                        },
                        [PCAReportMetric.Time]: {
                            expectedLabel: 'Expected Time Played',
                            actualLabel: 'Total Time Played',
                            percentageLabel: 'Time Played Percentage',
                            performanceLabel: 'Time Played Performance',
                            missingLabel: 'Missing Time Played',

                            overallCompareExpectedVsActualTitle: 'Expected vs Total Time Played',
                            overallCompareByPerformanceTitle: 'By Performance %',

                            overallTabTotalTabExpectedTitle: 'Time Target',
                            overallTabTotalTabActualTitle: 'Total Time Played',
                            overallTabTotalValueLabel: '{{count}} Time Played',
                            overallTabTotalPerformance: 'Time Played',

                            mapSettingActualHeat: 'Time',
                            mapSettingExpectedHeat: 'Expected Time',
                        },

                        energyUsage: {
                            playsHeader: 'Plays',
                            playTimeHeader: 'Plays Time',
                            kwhHeader: 'kWh',
                            kgCo2eHeader: 'kg CO2e',
                        },

                        map: {
                            markersToggle: 'Screens',
                            hideHeatmap: 'Hide Heatmap',
                            noHeatmapSelected: 'Select Heatmap',
                            heatmapPerformance: '% Performance',
                            heatmapInSchedule: 'In Schedule',
                            heatmapOutSchedule: 'Out Schedule',
                            heatmapUnbooked: 'Unbooked',
                        },
                        fields: {
                            [PCAReportField.Town]: '$t(CommonWords.Town)',
                            [PCAReportField.TvRegion]: '$t(CommonWords.TVRegion)',
                            [PCAReportField.Conurbation]: '$t(CommonWords.Conurbation)',
                            [PCAReportField.Environment]: 'Environment',
                            [PCAReportField.Format]: 'Format',
                            [PCAReportField.MediaOwner]: '$t(CommonWords.MediaOwner)',
                            [PCAReportField.LineItemName]: 'Line Item',
                            [PCAReportField.SurfaceArea]: 'Surface Area',
                            [PCAReportField.Resolution]: 'Resolution (W x H)',
                            [PCAReportField.CreativeName]: 'Creative Name',
                            [PCAReportField.FrameId]: '$t(CommonWords.Frame) ID',
                            [PCAReportField.DayOfWeek]: 'Day of Week',
                            [PCAReportField.Hour]: 'Hour',

                            // elastic search
                            [EsPCAReportField.Audience]: 'Audience',
                            [EsPCAReportField.AudienceSov]: 'Audience Sov',
                            [EsPCAReportField.CampaignId]: 'Campaign ID',
                            [EsPCAReportField.CarbonEmissionKgsCo2e]: 'kg CO2e',
                            [EsPCAReportField.CreativeId]: 'Creative ID',
                            [EsPCAReportField.CreativeName]: 'Creative Name',
                            [EsPCAReportField.Day]: 'Day #',
                            [EsPCAReportField.DisplayConurbation]: '$t(CommonWords.Conurbation)',
                            [EsPCAReportField.DisplayEnv]: 'Environment',
                            [EsPCAReportField.DisplayFileTypes]: 'File Types',
                            [EsPCAReportField.DisplayFormat]: 'Format',
                            [EsPCAReportField.DisplayId]: 'Display ID',
                            [EsPCAReportField.DisplayLat]: 'Display Lat',
                            [EsPCAReportField.DisplayLineItemId]: 'Line Item ID',
                            [EsPCAReportField.DisplayLineItemName]: 'Line Item Name',
                            [EsPCAReportField.DisplayLong]: 'Display Long',
                            [EsPCAReportField.DisplayOwner]: '$t(CommonWords.MediaOwner)',
                            [EsPCAReportField.DisplayResolution]: 'Resolution (W x H)',
                            [EsPCAReportField.DisplayScreenNameSummary]: 'Screen Name',
                            [EsPCAReportField.DisplaySlotDuration]: 'Display Slot Duration',
                            [EsPCAReportField.DisplaySlots]: 'Display Slots',
                            [EsPCAReportField.DisplaySov]: 'Display Sov',
                            [EsPCAReportField.DisplaySurfaceArea]: 'Surface Area',
                            [EsPCAReportField.DisplayTown]: '$t(CommonWords.Town)',
                            [EsPCAReportField.DisplayTvRegion]: '$t(CommonWords.TVRegion)',
                            [EsPCAReportField.DisplayType]: 'Display Type',
                            [EsPCAReportField.Dow]: 'Day Of Week',
                            [EsPCAReportField.Downtime]: 'Downtime',
                            [EsPCAReportField.EndDateTime]: 'End Date Time',
                            [EsPCAReportField.EndDate]: 'End Date',
                            [EsPCAReportField.EnergyUsesKwh]: 'Energy Usage kWh',
                            [EsPCAReportField.Environment]: 'Environment',
                            [EsPCAReportField.ExpectedImpacts]: 'Expected Impacts',
                            [EsPCAReportField.ExpectedImpactsInSchedule]:
                                'Expected Impacts In Schedule',
                            [EsPCAReportField.ExpectedPlays]: 'Expected Plays',
                            [EsPCAReportField.ExpectedPlaysInSchedule]:
                                'Expected Plays In Schedule',
                            [EsPCAReportField.ExpectedTime]: 'Expected Time',
                            [EsPCAReportField.ExpectedTimeInSchedule]: 'Expected Time In Schedule',
                            [EsPCAReportField.FrameId]: '$t(CommonWords.Frame) ID',
                            [EsPCAReportField.Hour]: 'Hour of Day',
                            [EsPCAReportField.ImpactsReceived]: 'Impacts Received',
                            [EsPCAReportField.ImpactsReceivedInSchedule]:
                                'Impacts Received In Schedule',
                            [EsPCAReportField.ImpactsReceivedOutOfSchedule]:
                                'Impacts Received Out of Schedule',
                            [EsPCAReportField.ImpactsReceivedUnbooked]: 'Impacts Received Unbooked',
                            [EsPCAReportField.ImpactsWithVerification]: 'Impacts With Verification',
                            [EsPCAReportField.InsidePopDate]: 'Inside Pop Date',
                            [EsPCAReportField.IssueId]: 'Issue ID',
                            [EsPCAReportField.NumberOfUniqueCreatives]: 'No. of Unique Creatives',
                            [EsPCAReportField.PlaysReceivedInSchedule]:
                                'Plays Received In Schedule',
                            [EsPCAReportField.PlaysReceivedOutOfSchedule]:
                                'Plays Received Out of Schedule',
                            [EsPCAReportFilterField.Schedule]: 'Scheduled',
                            [EsPCAReportField.PlaysReceivedUnbooked]: 'Plays Received Unbooked',
                            [EsPCAReportField.PlaysWithVerification]: 'Plays With Verification',
                            [EsPCAReportField.Schedule]: 'Schedule',
                            [EsPCAReportField.SlotDuration]: 'Slot Duration',
                            [EsPCAReportField.Sov]: 'Sov',
                            [EsPCAReportField.StartDateTime]: 'Start Date Time',
                            [EsPCAReportField.StartDate]: 'Date',
                            [EsPCAReportField.SumOfPlays]: 'Sum of Plays',
                            [EsPCAReportField.SumOfTime]: 'Sum of Time',
                            [EsPCAReportField.TimeReceivedInSchedule]: 'Time Received In Schedule',
                            [EsPCAReportField.TimeReceivedOutOfSchedule]:
                                'Time Received Out of Schedule',
                            [EsPCAReportField.TimeReceivedUnbooked]: 'Time Received Unbooked',
                            [EsPCAReportField.TimeWithVerification]: 'Time With Verification',
                        },
                        advancedFiltering: {
                            title: 'Advanced Filtering',
                            reset: 'Reset Filters',
                            search: 'Search',
                        },
                        scheduleDropdownPlaceholder: 'Schedule',
                        inScheduleLabel: 'In Schedule',
                        outScheduleLabel: 'Out Schedule',
                        unbookedLabel: 'Unbooked',
                        cumulativeLabel: 'Cumulative',
                        dateLabel: 'Date',
                        hourLabel: 'Hour',
                        areaLabel: 'Area',
                        byHourTableTimeHeader: 'Time',
                        typeOverall: 'Overall',
                        typeOverTime: 'Over Time',
                        selectedTotalPlaceholder: '{{selected}}/{{total}} Selected',
                        overallLeaderboard: {
                            title: 'Leaderboards',
                            filterResults: 'Filter Results',
                        },
                        frameList: {
                            frameTabAll: 'All ({{count}})',
                            frameTabBooked: 'Booked ({{count}})',
                            frameTabUnbooked: 'Unbooked ({{count}})',
                            showingResults: 'Showing {{count}} of {{total}}',
                            addressHeader: 'Address',
                            closeDialogBtn: 'Close',
                            noHourData: '-',
                            dayOverall: 'Overall',

                            timePlayedTotal:
                                'Played: {{hours}} hours, {{minutes}} minutes, {{seconds}} seconds',
                            timePlayedExpected:
                                'Expected: {{hours}} hours, {{minutes}} minutes, {{seconds}} seconds',
                            timePlayedOutOfSchedule:
                                'Out Of Schedule: {{hours}} hours, {{minutes}} minutes, {{seconds}} seconds',
                            timePlayedOutOFHours:
                                'Out Of Hours: {{hours}} hours, {{minutes}} minutes, {{seconds}} seconds',
                        },
                    },
                    Analytics: {
                        playsTitle: 'Plays',
                        impactsTitle: '$t(CommonWords.Impacts)',
                        timeTitle: 'Time Played',
                        expected: 'Expected',
                        actual: 'Total',
                        performance: 'Performance',
                        inSchedule: 'In Schedule',
                        outSchedule: 'Out Schedule',
                        unbooked: 'Unbooked',

                        reportTitles: {
                            totalImpacts: 'Running Total $t(CommonWords.Impacts)',
                            totalPlays: 'Running Total Plays',
                            impactsDayOfWeek: '$t(CommonWords.Impacts) by Day of Week',
                            impactsHour: '$t(CommonWords.Impacts) by Hour',
                            impactsHeatmap: '$t(CommonWords.Impacts) Heatmap',
                            impactsLineItem: '$t(CommonWords.Impacts) by Line Item',
                            impactsRegion: '$t(CommonWords.Impacts) by $t(CommonWords.Region)',
                            impactsMediaOwner:
                                '$t(CommonWords.Impacts) by $t(CommonWords.MediaOwner)',
                        },
                    },
                    Creatives: {
                        uploadFile: 'Add Artwork',
                        supportedTypes: 'Supported files: JPG, PNG, GIF, MP4, MOV',
                    },
                    Costs: {
                        createdAtHeader: 'Created',
                        priceHeader: 'Price',
                        statusHeader: 'Status',
                        originalPriceHeader: 'Original Price',
                        displaysHeader: 'Display Count',
                        overrideReasonHeader: 'Override Reason',

                        statusActive: 'Active',
                        statusInactive: 'Inactive',

                        lineItemsDialogTitle: 'Displays',
                        dayCount: '{{count}} days',

                        overrideDialog: {
                            title: 'Override Price',
                            description:
                                "As an administrator you can override the campaign's price.",

                            priceLabel: 'New Price',
                            reasonLabel: 'Reason',
                            failedOverride: 'Failed to override price',
                        },
                    },
                },
                CampaignSchedule: {
                    metaTitle: 'Schedule',
                    scheduleTabs: {
                        schedule: 'Schedule',
                        targets: {
                            tabTitle: 'Targets',
                            createTarget: 'Create Target',
                            updateTarget: 'Update Target',
                            deleteTarget: 'Delete Target',
                            plays: 'plays',
                            impacts: 'impacts',
                            attachTarget: 'Attach Target',
                            detachTarget: 'Detach Target',
                            target: 'Target',
                            multiplier: 'Multiplier',
                            multiplierSuccess: 'Pipeline task queued',
                            verification: {
                                name: 'Name is required',
                                type: 'Type is required',
                                target: 'Target is required',
                                targetErr: 'Target must be a number with up to 5 decimal places',
                                schedulesSelect: 'Select one or more schedules',
                                atLeastOneSelected: 'At least one schedule must be selected',
                            },
                        },
                    },
                    lineItems: 'Line Items',
                    displays: 'Line Item Displays',
                    inAPack: `In a $t(CommonWords.Pack)`,
                    table: {
                        scheduleDialogTitle: 'Schedules',
                        idCol: 'ID',
                        nameCol: 'Name',
                        ownerCol: '$t(CommonWords.Owner)',
                        frameIdCol: '$t(CommonWords.Frame) ID',
                        scheduleCol: 'Schedule',
                        sovCol: 'Sov',
                        viewAllSchedules: 'View all',
                        openPack: 'Open $t(CommonWords.Pack) ({{count}})',
                        closePack: 'Close',
                        actionsCol: 'Actions',
                        clearAll: 'Clear All',
                        tvRegion: '$t(CommonWords.TVRegion)',
                        sovDialog: {
                            header: 'Set SOV',
                            save: 'Save',
                        },
                    },
                    detachDialogTitle: 'Detach Schedules from {{name}}',
                    detachDialogButton: 'Detach',
                    upload: 'Schedule File Upload',
                    downloadTemplate: 'Download Template CSV',
                    uploadFile: 'Upload File',
                    override: 'Override',
                    popSettings: {
                        title: 'Pop Settings',
                        audienceVersionLabel: 'Audience Version',
                        creativesSearchLabel: 'Creatives Search',
                        creativesSearchDatesLabel: 'Between Dates',
                        logImportSource: 'Log Import Source',
                        uniqueCreativesHelper: '{{count}} unique creative names',
                        creativesLabel: 'Creatives',
                        energyEfficiencyLabel: 'Energy Efficiency Enabled',
                        displayVerificationEfficiencyLabel: 'Display Verification Enabled',
                        impressionMultiplierLabel: 'Impression Multiplier',
                        refreshDateLabel: 'Refresh Date',
                        refreshTypeLabel: 'Refresh Type',
                        minDate: 'Min Date',
                        minTime: 'Time',
                        graphReportTypeLabel: 'Graph Report Type',
                        logSourceCampaignIdentifiersTitle: 'Log Source Campaign Identifiers',
                        logSourceCampaignIdentifierSourceLabel: 'Source',
                        logSourceCampaignIdentifierLabel: 'Identifier',
                        saveSuccess: 'Pop Settings Saved',
                        submitBtn: 'Save',
                        search: 'Search',
                        name: 'Media Name',
                        id: 'ID',
                        source: 'Source',
                        creativeRef: 'Creative Ref',
                        campaignName: 'Campaign Name',
                        campaignExtId: 'Campaign Extid',
                        mediaExtId: 'Media Extid',
                        campaignRef: 'Campaign Ref',
                        firstSeen: 'First Seen',
                        lastSeen: 'Last Seen',
                        parameters: 'Parameters',
                        mediaSelection: 'Media Selection',
                        clear: 'Clear',
                    },
                    mediaTable: {
                        title: 'POP Media',
                        editParams: 'Edit Table Parameters',
                        exportCsv: 'Export Data To CSV',
                        idSearch: 'ID Search',
                        search: 'Search',
                        clear: 'Clear',
                        filterByParameters: 'Filter by Parameter',
                        applyChanges: 'Apply Changes',
                        apply: 'Apply',
                        searchCreatives: 'Search Creatives',
                        syncMedia: 'Sync Media',
                        paramsEditDialog: {
                            deleteSelected: 'Delete Selected',
                            mediaCount: 'Media Count',
                            actions: 'Actions',
                            noneFound: 'No parameters found for {{param}}.',
                            identifier: 'Identifier',
                        },
                        groups: {
                            createNewGroup: 'Create New Group',
                            addMediaToGroup: 'Add to {{name}} Group',
                            newGroupName: 'New Group Name',
                            additionMessage: 'The following media will be added to the group:',
                            confirm: 'Confirm',
                            removeFromGroups: 'Remove from Groups',
                            removeMediaFromGroups: 'Remove the following media from all Groups',
                            all: 'All',
                            ungrouped: 'Ungrouped',
                        },
                    },
                    ganttPopup: {
                        scheduleName: 'Schedule Name',
                        hours: 'Hours',
                        days: 'Days',
                        dateRange: 'Date Range',
                    },
                    filters: {
                        searchLabel: 'Search',
                        environmentDropdownLabel: 'Environment',
                        resolutionDropdownLabel: 'Resolution',
                        sizeDropdownLabel: 'Format',
                        ownerDropdownLabel: '$t(CommonWords.MediaOwner)',
                        frameIdFilter: '$t(CommonWords.Frame) ID filter',
                        scheduleFilter: 'Schedule Filter',
                        frameIds: '$t(CommonWords.Frame) IDs',
                        results: 'Results',
                    },
                    Schedules: {
                        scheduleName: 'Schedule {{count}}',
                        list: {
                            addScheduleBtn: 'Add Schedule',
                            sitelistUploadSuccess: 'Uploaded Site List',
                            templateDropdownPlaceholder: 'Search Templates',
                            manageTemplates: 'Manage Templates',
                            collapseAll: 'Collapse All',
                        },
                        deleteDialog: {
                            title: 'Are you sure you want to delete Schedule "{{name}}"',
                            description: 'This action cannot be undone.',
                            deleteBtn: 'Delete Schedule',
                        },
                        detachSchedules: 'Detach schedules from screens',
                        attachSchedules: 'Attach schedules to screens',
                        apply: 'Apply',
                    },
                    Schedulables: {
                        updateScheduleScreens: 'Update {{name}} Screens',
                        countLabel_one: '{{count}} screen',
                        countLabel_other: '{{count}} screens',
                        addSelectedToSchedule_one: 'Add {{count}} Selected Screen To Schedule',
                        addSelectedToSchedule_other: 'Add {{count}} Selected Screens To Schedule',
                        removeSelectedToSchedule_one:
                            'Remove {{count}} Selected Screen From Schedule',
                        removeSelectedToSchedule_other:
                            'Remove {{count}} Selected Screens From Schedule',

                        lineItemsListHeader: 'Line Items',
                        screenListHeader: 'Screens',

                        detailsDialog: {
                            title: '"{{name}}" Schedules',

                            currentSchedules: 'Current Schedules',
                            otherSchedules: 'Other Schedules',
                            saveBtn: 'Save',
                        },
                    },
                    summary: {
                        sov: 'SOV',
                        slotLength: 'Slot Length',
                    },
                    Form: {
                        newSchedule: 'New Schedule',
                        updateSchedule: 'Update {{name}}',
                        cloneSchedule: 'Clone {{name}}',
                        rulesHeader: 'Rules',
                        dateRangeLabel: 'Date Range',
                        campaignDurationShortcut: 'Campaign Duration',
                        scheduleNameLabel: 'Name',
                        startTimeLabel: 'Start Time',
                        endTimeLabel: 'End Time',
                        timeHeader: 'Time',
                        weekdaysHeader: 'Weekdays',
                        sovHeader: 'SOV',
                        slotLengthHeader: 'Slot Length',
                        slotLengthHelperText: 'Slot Length Must Be Greater Than 0',
                    },
                },
                DataStatus: {
                    metaTitle: 'Data Status',
                    description: 'Monitor the data status of campaigns',
                    table: {
                        dataStatusCol: 'Data Status',

                        bookerCol: 'Booker',
                        campaignOwnerCol: 'Campaign $t(CommonWords.Owner)',
                        campaignAssigneeCol: 'Campaign Assignee',

                        awaitingDataCol: 'Missing Data',
                        inspectingDataCol: 'Data Query',
                        awaitingBookingPlanCol: 'Missing Booking Data',

                        noticesMediaOwnersTitle: '$t(CommonWords.MediaOwners)',
                        noticesLineItemsTitle: 'Line Items',

                        bookingSummaryCol: 'Booking Summary',
                        actionRequiredCol: 'Action Required',
                        gathererCol: 'Gatherer',
                        scheduler: 'Scheduler',
                        reviewer: 'Reviewer',
                        bookingSummaryValidated: 'Booking Summary Signed Off',

                        bookingSummaryFileCount_one: '{{count}} File',
                        bookingSummaryFileCount_other: '{{count}} Files',

                        reportingColOverall: 'Overall:',

                        lastContactedMoCol: 'Last Contacted Date MO',

                        signOffCol: 'Sign off',

                        signOffCheckboxLabel: 'Sign Off',
                        inflightReportSentCheckboxLabel: 'Inflight Report Sent',
                        pcaReportSentCheckboxLabel: 'PCA Report Sent',

                        highPriorityCol: 'High Importance',
                        notesCol: 'Notes',
                        noteFailedSave: 'Error Saving Note',
                        ragCol: 'RAG',
                        plannerAwareCol: 'Planner Aware',
                        reportingCol: 'Reporting',
                    },
                    contacts: {
                        saveButton: 'Save',
                        contactsDropDownLabel: 'Edit Contacts',
                        otherContactsLabel: 'Other contacts',
                        copyEmailsLabel: 'Copy all emails',
                    },
                    awaitingDataFilterLabel: 'Awaiting Data from Vendor',
                    inspectingDataFilterLabel: 'Data received in query with Vendor',
                    awaitingBookingPlanFilterLabel: 'Booking Detail Outstanding',
                    awaitingDataLabel: 'Missing Data',
                    inspectingDataLabel: 'Data Query',
                    awaitingBookingPlanLabel: 'Missing Booking Data',
                    resetDataStatusLabel: 'Reset Data Status',
                    awaitingDataLabelAlert: 'Awaiting Data from Vendor',
                    inspectingDataLabelAlert: 'Data received in query with Vendor',
                    awaitingBookingPlanLabelAlert: 'Booking Detail Outstanding – please contact ',
                    bookingPlanValidatedLabelAlert:
                        'Booking Detail Requiring Sign Off – please contact ',
                    LastContactedMediaOwner: {
                        mediaOwnerCol: '$t(CommonWords.MediaOwner)',
                        percentageCol: 'Percentage',
                        lastContactedCol: 'Last contacted MO',
                        saveDate: 'Save',
                    },
                },
                Instructions: {
                    openDialogBtnlabel: 'Instructions',
                    dialogTitle: 'Instructions',
                    emptyInstructions: 'There are no comments or Instructions added yet',
                    newMessagePlaceholder: 'Add a comment...',
                    failedToLoad: 'Failed to load instructions',
                    failedToLoadRetry: 'Reload',
                },
                PopShots: {
                    table: {
                        nameCol: 'Image file name',
                        fileSizeCol: 'File size',
                        uploadedCol: 'Uploaded Date - Time',
                        displayCol: 'Allocated display',
                        actionsCol: 'Actions',
                        showProPopShots: 'Only Professional Images',
                        isPro: 'Pro',
                    },
                    upload: {
                        title: 'Upload Pop Images',
                        lineItemLabel: 'Select Display',
                        isProLabel: 'Professional Image?',
                    },
                    delete: {
                        title: 'Delete Pop Shot',
                        description:
                            'Are you sure you want to delete "{{name}}"? This action cannot be undone.',
                    },
                },
            },
            CampaignError: {
                errorableType: {
                    [CampaignErrorableType.Creative]: {
                        errorTitles: {
                            [CampaignErrorType.NoMatchingLineItemResolutions]: 'Invalid Resolution',
                            [CampaignErrorType.CreativeMissingResolution]: 'Missing Resolution',
                            [CampaignErrorType.MissingAnalogueLineItem]: 'No Analogue Displays',
                            [CampaignErrorType.IncorrectImageAnimation]: 'No Image Displays',
                            [CampaignErrorType.IncorrectVideoAnimation]: 'No Video Displays',
                            [CampaignErrorType.MissingLineItemMimeType]: 'Invalid File Type',
                        },
                        expected: {
                            [CampaignErrorType.NoMatchingLineItemResolutions]:
                                'There are no displays with a resolution of {{expected}}.',
                        },
                        available: {
                            // [CampaignErrorType.NoMatchingLineItemResolutions]: 'example override for available description {{available}}.',
                        },
                    },
                    [CampaignErrorableType.LineItem]: {
                        errorTitles: {
                            [CampaignErrorType.NoMatchingResolution]:
                                "No creatives for this line item's resolution",
                            [CampaignErrorType.IncorrectAnimation]:
                                "No creatives for this line item's animation type",
                            [CampaignErrorType.IncorrectImageAnimation]:
                                'No image creatives for this line item',
                            [CampaignErrorType.IncorrectFileAnalogue]:
                                'No analogue creatives for this line item',
                        },
                    },
                    [CampaignErrorableType.Campaign]: {
                        errorTitles: {
                            [CampaignErrorType.NoMatchingResolution]:
                                'Missing Creative Resolutions',
                            [CampaignErrorType.MissingCreativeResolutions]:
                                'Creatives With No Resolution Information',
                            [CampaignErrorType.NoMatchingResolutionCreatives]:
                                'Creatives Missing Equivalent Line Item Resolutions',
                            [CampaignErrorType.NoMatchingImageCreatives]:
                                'Missing Line Items For Playing Image',
                            [CampaignErrorType.NoMatchingAnalogueCreatives]:
                                'Missing Line Items For Playing Analogue',
                            [CampaignErrorType.NoMatchingVideoCreatives]:
                                'Missing Line Items For Playing Video',
                        },
                        expected: {
                            // [CampaignErrorType.NoMatchingResolution]: '{{expected}}',
                        },
                    },
                },
                expectedTitle: 'Expected',
                availableTitle: 'Available',
            },
            CampaignDisplay: {
                List: {
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                        ownerCol: '$t(CommonWords.Owner)',
                        resolutionCol: 'Resolution (W x H)',
                        creativeCountCol: 'Creatives',
                        downloadCol: 'Download',
                    },
                },
            },
            Creative: {
                SearchPage: {
                    metaTitle: 'Search System Creatives',
                    description: 'Search creatives from the whole system',

                    filterExpandButtonLabel: 'Filter',
                    nameSearchLabel: 'Filter by Name',
                    campaignSearchLabel: 'Filter by Campaign',
                    agencySearchLabel: 'Filter by Agency',
                    resolutionSearchLabel: 'Filter by Resolution (W x H)',
                    creativeTypeSearchLabel: 'Filter by Type',

                    creativeCount: '{{count}} of {{total}}',

                    creativeTypes: {
                        group: 'Group',
                        file: 'File',
                        image: 'Image',
                        video: 'Video',
                    },
                },
                List: {
                    table: {
                        idCol: 'ID',
                        nameCol: 'Name',
                    },
                },
            },
            DisplayIssues: {
                details: {
                    caseRecordType: 'CASE RECORD TYPE',
                    initialDiagnosis: 'INITIAL DIAGNOSIS',
                    description: 'DESCRIPTION',
                    priority: 'PRIORITY',
                },
                table: {
                    displayNameCol: 'Display affected',
                    startDateCol: 'Start Date',
                    startTimeCol: 'Start Time',
                    endDateCol: 'End Date',
                    endTimeCol: 'End Time',
                    downtimeCol: 'Downtime',
                    incidentIdCol: 'Incident ID',
                },
            },
            CreativeManagement: {
                groups: {
                    addGroup: 'Add Group',
                    header: 'Groups',
                    autoGroupsLabel: 'Auto',
                    allArtwork: 'All Creatives',
                    allArtworkRemove: 'All Groups',
                    invalidGroup: 'Invalid',
                    ungroupedGroup: 'Ungrouped',
                    unassignedGroup: 'Unassigned',
                    newGroupPlaceholder: 'New Group',

                    autoGroups: {
                        dialogTitle: 'Auto Groups Generation',

                        groupNamesHeader: 'Group names',
                        creativesCountHeader: 'Creatives',
                        createGroupsLabel: 'Create Groups',

                        generateGroupsErrorTitle: 'Oh no!',
                        generateGroupsRetryLabel: 'Refresh',
                    },
                },
                dragAndDrop: {
                    dragCount_one: '{{count}} creative',
                    dragCount_other: '{{count}} creatives',
                    dragMoveFailedTitle: 'Failed to move creatives',
                },
                warnings: {
                    invalidCreatives: 'Invalid Creatives',
                    filterAllButtonLabel: 'FILTER ALL',
                    campaignNeedsValidating: 'Creatives Require Validation',
                    revalidateCampaignLabel: 'VALIDATE NOW',
                    groupWarningCount_other: '{{count}} creative warnings',
                    groupWarningCount_one: '{{count}} creative warning',
                    duplicateFileNameWarning:
                        'The following file:  {{file}} have duplicate file names would you like to update these files?',
                    duplicateFileYesButton: 'Yes',
                    duplicateFileNoButton: 'No',
                },
                creatives: {
                    filters: {
                        reset: 'Reset',
                        apply: 'ApplyFilters',
                        searchPlaceholder: 'Search',
                        resolutionsPlaceholder: 'Resolution (W x H)',
                        aspectRatiosPlaceholder: 'Aspect Ratio',
                        fileTypesPlaceholder: 'Type',
                        errorsPlaceholder: 'By Errors',
                        durationLabel: 'Duration',
                        liveDateLabel: 'Live Date',
                        endDateLabel: 'End Date',
                        dateAddedLabel: 'Date Added',
                        errorLabels: {
                            [CampaignErrorType.NoMatchingLineItemResolutions]: 'Invalid Resolution',
                            [CampaignErrorType.CreativeMissingResolution]: 'Missing Resolution',
                            [CampaignErrorType.MissingAnalogueLineItem]: 'No Analogue Displays',
                            [CampaignErrorType.IncorrectImageAnimation]: 'No Image Displays',
                            [CampaignErrorType.IncorrectVideoAnimation]: 'No Video Displays',
                            [CampaignErrorType.MissingLineItemMimeType]: 'Invalid File Type',
                        },
                    },
                    table: {
                        nameCol: 'File name',
                        groupCol: 'Group',
                        typeCol: 'Type',
                        durationCol: 'Duration',
                        resCol: 'Resolution (W x H)',
                        aspectRatioCol: 'Ratio',
                        fileSizeCol: 'File Size',
                        liveDataCol: 'Live date',
                        endDataCol: 'End date',
                        uploadDateCol: 'Date added',
                        assignedCol: 'Assigned Displays',
                        updatedAtCol: 'Updated At',
                        actionsCol: 'Actions',
                        groupNameCol: 'Group',
                        downloadAction: 'Download',
                        deleteAction: 'Delete',
                        deleteNoPermission: 'You do not have permission to delete this creative',
                        assignAction: 'Assign',
                        dropMessage: 'Drop files here to upload',
                        dropDialogTitle: 'Uploading Files',
                        dropDialogCloseBtn: 'Close',

                        copyBtnNoSelected: 'Select creatives to copy',
                        copyBtnDefault: 'Copy selected',
                        copyBtnCopied: 'Copied!',
                        copyBtnFailed: 'Failed copy',
                    },
                    groups: {
                        deleteGroup: {
                            dialogTitle: 'Delete "{{name}}"',
                            dialogDescription:
                                'You can choose whether to keep the creatives in the "All Creatives" group, or whether to delete the group\'s creatives too.',
                            alsoDeleteCreativesLabel: 'Also Delete Creatives?',
                            deleteBtnLabel: 'Delete',
                        },
                    },
                    controls: {
                        selectedCount: '{{count}} selected',
                        downloadBtnLabel: 'Download',
                        moveBtnLabel: 'Move to',
                        deleteBtnLabel: 'Delete',
                        uploadBtnLabel: 'Upload',
                        liveDateBtnLabel: 'Set Live Dates',
                        endDateBtnLabel: 'Set End Dates',

                        move: {
                            title: 'Move Selected Files',
                            allArtwork: 'All Creatives',
                            chooseGroupLabel: 'Choose New Groups',
                            submitLabel: 'Submit',
                        },
                        delete: {
                            title: 'Delete Selected Files',
                            description_one:
                                'Are you sure you want to delete the selected creative?\nThis action cannot be undone.',
                            description_other:
                                'Are you sure you want to delete the selected creatives?\nThis action cannot be undone.',
                            buttonLabel_one: 'Delete Creative',
                            buttonLabel_other: 'Delete Creatives',

                            noPermission:
                                'You do not have permission to delete the following creatives:',
                            noPermissionTwo:
                                'Would you like to delete the other selected creatives?',
                        },
                        upload: {
                            title: 'Upload Artwork',
                            dropzoneText: 'Drag & drop files or Browse',
                            browseBtnLabel: 'Browse',
                            cancel: 'Cancel',
                            closeAnyway: 'Close Anyway',
                            fileList: 'The following files are still uploading : ',
                            confirmation:
                                ' Are you sure you want to proceed? This will cancel the ongoing uploads.',
                            uploadsInProgress: 'Uploads in progress',
                        },
                        dates: {
                            updateLiveDateTitle: 'Update Live Dates',
                            updateLiveDateDescription:
                                'Set the live dates of all selected files in one go.',
                            updateEndDateTitle: 'Update End Dates',
                            updateEndDateDescription:
                                'Set the end dates of all selected files in one go.',
                            submitLabel: 'Set Dates',
                        },
                        rename: {
                            title: 'Rename Creative',
                            description: 'Rename "{{name}}"',
                            nameLabel: 'New Name',
                            saveLabel: 'Rename',
                            previewDialogNameLabel: 'Name',
                            previewDialogSave: 'Save',
                        },
                    },
                    assignDialog: {
                        lineItemNameCol: 'Display',
                        mediaOwnerCol: '$t(CommonWords.MediaOwner)',
                        environmentCol: 'Environment',
                        sizeCol: 'Format',
                        resolutionsCol: 'Resolutions',
                        fileTypesCol: 'File Types',

                        dialogTitle: 'Force Assign',
                        dialogDescription:
                            'Please choose which displays to assign this creative to by checking the checkboxes below',
                        assignButtonLabel: 'assign selected',

                        lineItemResolutionMismatch: 'Resolution does not match',
                        lineItemAlreadyAssigned: 'Already Assigned',
                        failedToAssign:
                            'An error has occurred while trying to assign creatives to the selected displays',
                        failedToLoadAssignData: 'Failed to load',
                        failedToLoadAssignDataRetry: 'Reload',

                        searchLabel: 'Search',
                        mediaOwnerDropdownLabel: '$t(CommonWords.MediaOwner)',
                        environmentDropdownLabel: 'Environment',
                        sizeDropdownLabel: 'Format',
                        resolutionDropdownLabel: 'Resolution (WxH)',
                    },

                    unAssignDialog: {
                        dialogTitle: 'Force UnAssign',
                        dialogDescription:
                            'Please choose which displays to unassign this creative from by checking the checkboxes below',
                        unAssignButtonLabel: 'unassign selected',
                        failedToUnAssign:
                            'An error has occurred while trying to unassign creatives from the selected displays',
                    },
                },
            },
            MyProfile: {
                metaTitle: 'My Profile',
                description: 'My user profile',
            },
        },
        Auth: {
            User: {
                UserProfileCard: {
                    joined: 'Joined',
                    from: 'From:',
                },
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                    emailCol: 'Email',
                    languageCol: 'Language',
                    buyerCol: 'Buyer',
                    agencyCol: 'Agency',
                    ownerCol: '$t(CommonWords.Owner)',
                    rolesCol: 'Roles',
                    buyerDialogTitle: 'Buyers',
                    roleDialogTitle: 'Roles',
                    agencyDialogTitle: 'Agencies',
                    ownerDialogTitle: '$t(CommonWords.Owners)',
                },
            },
        },
        PopDisplays: {
            adminHomeSectionTitle: 'Pop Displays',
            AdminHome: {
                metaTitle: 'Pop Displays Admin',
                description: 'Pop Displays Admin Dashboard',
                logsTitle: 'Log Files',
            },
            JobStatus: {
                metaTitle: 'Task Queue',
                refresh: 'Refresh Interval (ms)',
                setTime: 'Set time',
                description: 'Tasks queue list page',
                table: {
                    idCol: 'ID',
                    nameCol: 'Task Name',
                    createdCol: 'Created',
                    startedAtCol: 'Started',
                    completedAtCol: 'Completed',
                    updatedAtCol: 'Updated',
                    durationCol: 'Duration',
                    statusCol: 'Status',
                    stopTaskLabel: 'Stop Task',
                },
            },
            LogFileImport: {
                metaTitle: 'Log File Ingestion Explorer',
                description: 'View a list of log files ingested by the system.',
                table: {
                    idCol: 'ID',
                    typeCol: 'Type',
                    statusCol: 'Status',
                    dateCol: 'Date',
                    fileCol: 'File',
                    fileSizeCol: 'File Size',
                    createdCol: 'Created',
                    updatedCol: 'Updated',
                },
                details: {
                    resourcesSectionTitle: 'Resources',
                    parsedSectionTitle: 'Entries',
                    filterByTypeLabel: 'Type',
                    filterByNameLabel: 'Name',
                },
            },
            LogFileResource: {
                table: {
                    typeCol: 'Type',
                    indexCol: 'index',
                    countCol: 'Count',
                    nameCol: 'Name',
                    extIdCol: 'ExitId',
                    revisionCol: 'Revision',
                },
            },
            LogImportSource: {
                metaTitle: 'Log Import Sources',
                description: 'Log Import Sources',
                create: {
                    newSourceButton: 'New Source',
                    nameLabel: 'Name',
                    marketNameLabel: 'Select Market',
                    parseLimitLabel: 'Parse Limit',
                    archiveDateLimitLabel: 'Archive Date Limit',
                    archiveDeleteFilesLabel: 'Archive Delete Files',
                    activeLabel: 'Active',
                },
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                    dirNameCol: 'Directory Name',
                    marketCol: 'Market',
                    parseLimit: 'Parse Limit',
                    archiveDeletedFilesCol: 'Archive Del. Files',
                    activeCol: 'Active',
                },
            },
            PlayoutExport: {
                metaTitle: 'Playout Export',
                description: 'Playout Export',
                sourcesLabel: 'Sources',
                submitLabel: 'Generate Export',
                successDefault: 'Your CSV export request has been will be emailed to your inbox',
                errorDefault: 'Failed to request export',
            },
            VirtualCampaignBuilder: {
                metaTitle: 'Virtual Campaign Builder',
                description: 'Generate reports for virtual campaigns',
                form: {
                    startDateLabel: 'Start Date',
                    endDateLabel: 'End Date',
                    marketLabel: 'Market',
                    ownerLabel: '$t(CommonWords.MediaOwners)',
                    mediaItemsTextarea: 'Media Items',
                    submitBtnLabel: 'Find Campaign Results',
                },
                summary: {
                    standardReport: 'Generate Report',
                    displaysReport: 'Generate Displays Report',
                    table: {
                        nameCol: 'Name',
                        channelCol: 'Channel',
                        frameCol: '$t(CommonWords.Frame)',
                        frameIdCol: '$t(CommonWords.Frame) Id',
                        slotsCol: 'Slots',
                        slotsDurationCol: 'Slots Duration',
                        ownerCol: '$t(CommonWords.MediaOwner)',
                        sourceCol: 'Source',
                        actualCol: 'Actual',
                        count: 'Count: {{count}}',
                        secs: 'Secs: {{count}}',
                        time: 'Time: {{count}}',
                        minDateCol: 'Min Date',
                        maxDateCol: 'Max Date',
                        playbackSummaryLabel: 'Playback Summary',
                        playbackSummaryDialogTitle: '"{{name}}" Playback Summary',
                    },
                },
            },
            Display: {
                metaTitle: 'Display Explorer',
                description: 'Displays List',
                table: {
                    idCol: 'ID',
                    sourceCol: 'Source',
                    nameCol: 'Name',
                    channelCol: 'Channel',
                    frameCol: '$t(CommonWords.Frame)',
                    displayKeyCol: 'Key',
                    ownerCol: '$t(CommonWords.Owner)',
                    frameIdCol: '$t(CommonWords.Frame) ID',
                    displayPortalIdCol: 'Display Portal Id',
                    sizeCol: 'Format',
                    resolutionCol: 'Resolution',
                    slotsCol: 'Slots',
                    slotLengthCol: 'Slot Length',
                    envCol: 'Environment',
                    animationCol: 'Animation',
                    marketCol: 'Market',
                    deleteDisplayTitle: 'Delete Display',
                    deleteDisplayDescription:
                        'Are you sure you want to delete "{{name}}"? This action cannot be undone.',
                    deleteButtonLabel: 'Delete Display',
                },
                newDisplay: {
                    title: 'New Display',
                    newDisplayButton: 'New Display',
                    createDisplayButton: 'Create Display',
                    sourceLabel: 'Source',
                    displayPortalLabel: 'Displays Portal Display ID',
                    displayPortalSearchLabel: 'Displays Portal Display Search',
                    channelLabel: 'Channel',
                    frameLabel: '$t(CommonWords.Frame)',
                    displayKeyLabel: 'Key',
                },
                editDisplay: {
                    title: 'Edit "{{name}}"',
                },
                Overview: {
                    tabs: {
                        details: 'Details',
                        dates: 'Date Breakdowns',
                    },
                },
            },
            ChannelFrame: {
                metaTitle: 'Channel Explorer',
                description: 'Channel $t(CommonWords.Frame) List',
                import: {
                    btnLabel: 'Upload',
                    title: 'Channel $t(CommonWords.Frame)s Upload',
                    description:
                        "Use this to map channel $t(CommonWords.frame)s to display portal's displays.\n\nUploaded file needs to be a .csv file and contain 'channel_frame_id' and 'displays_portal_id' columns.",
                },
                table: {
                    idCol: 'ID',
                    sourceCol: 'Source',
                    channelCol: 'Channel',
                    frameCol: '$t(CommonWords.Frame)',
                    uuidCol: 'Uuid',
                    playerCol: 'Player',
                    displayIdCol: 'Display Id',
                    displayPortalIdCol: 'Display Portal Id',

                    removeFrameTitle: 'Remove "{{name}}" from display',
                    removeFrameDescription:
                        'Removing this $t(CommonWords.frame) will disassociate it from this display. It will not delete the $t(CommonWords.frame). This action cannot be undone.',
                    confirmRemoveFrame: 'Remove $t(CommonWords.Frame) From Display',

                    addFrameBtn: 'Add Channel $t(CommonWords.Frame)s',
                    addFrameTitle: 'Add Channel $t(CommonWords.Frame)s',
                    addFrameNoDisplayCheckbox: '$t(CommonWords.Frame)s without displays',
                    addFrameDropdown: 'Channel $t(CommonWords.Frame)s',
                },
                details: {
                    displaysHeader: 'Displays',
                    dateBreakdownHeader: 'Date Breakdown',
                    mediaBreakdownHeader: 'Media Breakdown',
                    countLabel: 'Count',
                    durationLabel: 'Duration',
                    minDateLabel: 'Min Date',
                    maxDateLabel: 'Max Date',
                },
            },
            ChannelFrameMediaSummary: {
                table: {
                    mediaCol: 'Media',
                    durationCol: 'Duration',
                    countCol: 'Count',
                    minDateCol: 'Min Date',
                    maxDateCol: 'Max Date',
                    playbackSummaryBtn: 'Playback Summary',
                    playbackSummaryTitle: '"{{name}}" Playback Summary',
                },
            },
            ScheduleTemplates: {
                metaTitle: 'Scheduling Templates',
                description: 'Manage Scheduling Templates',
                table: {
                    idCol: 'ID',
                    nameCol: 'Name',
                    actionsCol: 'Actions',
                },
                schedules: {
                    add: 'Add Schedule',
                },
            },
        },
        Displays: {
            deAllocation: 'Deallocate Screens',
            tagRemoval: 'You Have Successfully Removed The Selected Screens From This Tag',
            packRemoval:
                "'You Have Successfully Removed The Selected Screens From This $t(CommonWords.Pack)",
            adminHomeSectionTitle: 'Displays',
            AdminHome: {
                metaTitle: 'Displays Admin',
                description: 'Displays Admin Dashboard',
            },
            manualAudience: {
                version: 'Audience Version',
                file: 'File',
                submit: 'Submit',
            },
            vendorIds: {
                dialogTitle: 'Are You Sure That You Want To Delete This Vendor',
                id: 'ID',
                name: 'Name',
                deleteVendorId: 'Delete',
                yes: 'Yes',
                no: 'No',
            },
            OperationalTimings: {
                id: 'ID',
                type: 'Type',
                day: 'Day',
                date: 'Date',
                startTime: 'Start Time',
                endTime: 'End Time',
                action: 'Action',
                recurring: 'Recurring',
                schedule: 'Schedule',
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
                weekdays: 'Weekdays',
                weekends: 'Weekends',
                everyday: 'Everyday',
                chooseADay: 'Choose A Day',
                submit: 'Submit',
                selectOption: 'Select An Option',
                entries: 'Entries',
                del: 'Delete',
                entry: 'Entry',
            },
            DisplaysDisplayEdit: {
                metaTitle: 'Edit Display',
                description: 'Edit Display',
                submit: 'Submit',
                deleteDialog: {
                    title: 'Are you sure you want to delete this screen?',
                    yes: 'Yes',
                    no: 'No',
                },
                delete: 'Delete',
                deleteWithCampaign:
                    'You have attempted to remove a display that has a creative(s) assigned to it. Please un-assign the creative(s) from the display and try again',
                deleteWithResolution:
                    'You have attempted to remove a display that has a resolution(s) assigned to it. Please un-assign the resolution(s) from the display and try again',
                success: 'Display successfully edited',
                brochureWarning:
                    'The screen belongs to a $t(CommonWords.Pack). $t(CommonWords.Pack)s brochure supersedes.',
                brochureSuccess: 'Brochure Successfully Added',
                tabs: {
                    displayTab: 'Display',
                    operationalTimingsTab: 'Operational Timings',
                    tagTab: 'Tags',
                    emptyTagTab: 'No Tags exist in this display',
                    emptyOwnersTab: 'No $t(CommonWords.MediaOwners) exist on this display',
                    emptyPacksTab: 'No $t(CommonWords.Pack)s exist on this display',
                    ownersTab: '$t(CommonWords.Owners)',
                    packsTab: '$t(CommonWords.Pack)s',
                    vendorTab: 'Vendor IDs',
                    audience: 'Audience',
                },
                audience: {
                    table: {
                        headers: {
                            versionId: 'Version Id',
                            versionName: 'Name',
                            releaseId: 'Release Id',
                            fallback: 'Fallback',
                            validDateFrom: 'Valid Date From V',
                            fDay: 'f Day',
                            fHour: 'f Hour',
                            audience: 'Audience',
                            sov: 'SOV',
                            uploadedBy: 'Uploaded By',
                        },
                        body: {
                            yes: 'Yes',
                            no: 'No',
                            NA: 'N/A',
                        },
                    },
                    filters: {
                        dateFrom: 'Date From',
                        days: 'Days',
                        hours: 'Hours From',
                        submit: 'Submit',
                    },
                },
                fields: {
                    type: 'Type',
                    resolutions: 'Resolutions',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                    town: '$t(CommonWords.Town)',
                    postCode: 'Post Code',
                    slots: 'Slots',
                    slotLen: 'Slot Length',
                    resolutionWidth: 'Width',
                    resolutionHeight: 'Height',
                    address: 'Address',
                    market: 'Market',
                    location: 'Location',
                    salesForce: 'Sales Force Display ID',
                    partner: 'Vendor',
                    tvRegion: '$t(CommonWords.TVRegion)',
                    conurbation: '$t(CommonWords.Conurbation)',
                    channel: 'Channel',
                    animation: 'Animation',
                    size: 'Size',
                    fileType: 'File Types',
                    environment: 'Environment',
                    sa: 'Surface Area',
                    frame: '$t(CommonWords.Frame)',
                    sov: 'SOV %',
                    energyConv: 'Energy Conversion Overload',
                    energyUsage: 'Energy Uses per Second',
                    link: 'Brochure Link',
                    broFile: 'Brochure File',
                    mediaOwners: '$t(CommonWords.Owners)',
                    frameId: '$t(CommonWords.Frame) ID',
                    display: 'Display',
                    logo: 'Logo',
                    status: 'Status',
                    active: 'Active',
                    inactive: 'Inactive',
                    prevFrameId: 'Previous $t(CommonWords.Frame) ID',
                    static: 'Static',
                    motion: 'Motion',
                    subtle: 'Subtle',
                    downloadButton: 'Download',
                    downloadButtonLabel: 'Download Brochure',
                    uploadBrochure: 'Upload Brochure',
                    closeDialog: 'Continue',
                },
            },
            DisplaysTagEdit: {
                metaTitle: 'Tags Edit',
                description: 'Tags Edit',
                submit: 'Submit',
                success: 'Tag successfully edited',
                deleteDialog: {
                    title: 'Are you sure you want to delete this tag?',
                    yes: 'Yes',
                    no: 'No',
                },
                delete: 'Delete',
                tabs: {
                    details: 'Details',
                    operationalTimingsTab: 'Operational Timings',
                    displaysWOT: 'Displays With Operational Timings',
                },
                fields: {
                    name: "Tag's Name",
                    operational: 'Operational',
                    description: 'Tag Description',
                    parent: 'Parent',
                    inactive: 'Inactive',
                    active: 'Active',
                    market: 'Market',
                    partner: 'Vendor',
                },
            },
            DisplaysOwnerEdit: {
                metaTitle: '$t(CommonWords.Owner) Edit',
                description: '$t(CommonWords.Owner) Edit',
                submit: 'Submit',
                success: '$t(CommonWords.Owner) successfully edited',
                noPacks:
                    'There are no $t(CommonWords.pack)s that belong to this $t(CommonWords.owner)',
                noDisplays: 'There are no displays that belong to this $t(CommonWords.owner)',
                deleteDialog: {
                    title: 'Are you sure you want to delete this $t(CommonWords.owner)?',
                    yes: 'Yes',
                    no: 'No',
                },
                delete: 'Delete',
                deletedOwner:
                    '$t(CommonWords.Owner) no longer exists. Please click below to return to the $t(CommonWords.Owners) list page.',
                ownersList: '$t(CommonWords.Owners)',
                tabs: {
                    editOwnerDetails: 'Edit $t(CommonWords.Owner) Details',
                    packs: '$t(CommonWords.Pack)s',
                    displays: 'Displays',
                    massUpdating: 'Mass Updating Displays',
                },
                selectLabels: {
                    empty: 'No status Filter',
                    active: 'Active',
                    inactive: 'Inactive',
                },
                fields: {
                    country: 'Country',
                    name: '$t(CommonWords.Owners) Name',
                    partner: "Vendor's Name",
                    address1: 'Address line 1',
                    address2: 'Address line 2',
                    market: 'Market',
                    address3: 'Address line 3',
                    address4: 'Address line 4',
                    postcode: 'Postcode',
                    extId: 'External ID',
                    vendor: 'Vendor Name',
                    enable: 'Enable',
                    disable: 'Disable',
                },
            },
            DisplaysPackEdit: {
                metaTitle: '$t(CommonWords.Pack) Edit',
                description: '$t(CommonWords.Pack) Edit',
                submit: 'Submit',
                success: '$t(CommonWords.Pack) successfully edited',
                tabs: {
                    details: 'Details',
                    displayAllocation: 'Display Allocation',
                    opTimings: 'Operational Timings',
                },
                fields: {
                    resolutionWidth: 'Width',
                    resolutionHeight: 'Height',
                    market: 'Market',
                    owner: '$t(CommonWords.Owner)',
                    type: '$t(CommonWords.Pack) Type',
                    resolutions: 'Resolutions',
                    sov: 'SOV %',
                    size: 'Size',
                    animation: 'Animation',
                    slots: 'Slots',
                    slotLength: 'Slot Length',
                    env: 'Environment',
                    name: 'Name',
                    description: 'Description',
                    brochureLink: 'Brochure Link',
                    fileTypes: 'File Types',
                    updatedBy: 'Updated By',
                    uniliveId: 'Unilive ID',
                    pack: '$t(CommonWords.Pack)',
                    site: 'Site',
                    createdBy: 'Created By',
                    static: 'Static',
                    motion: 'Motion',
                    subtle: 'Subtle',
                    uploadBrochure: 'Upload Brochure',
                    replaceBrochure: 'Replace Brochure',
                    downloadButtonLabel: 'Download Brochure',
                    downloadButton: 'Download',
                    deleteButtonLabel: 'Delete Brochure',
                    deletePack: 'Are You Sure You Want To Delete This $t(CommonWords.Pack)',
                },
            },
            DisplaysList: {
                uploadManualAudience: 'Upload Manual Audience',
                downloadTemplate: 'Download Template',
                createButton: 'Create Screen',
                save: 'Save',
                metaTitle: 'Displays',
                description: 'Displays',
                downloadCSV: 'Download CSV',
                reImport: 'Re-import',
                allocation: 'Allocation',
                clearSelected: 'Clear Selected',
                massUpdating: 'Mass Update Displays',
                customFilters: {
                    frameIds: '$t(CommonWords.Frame) Ids',
                },
                allocationDialog: {
                    title: 'Screen Allocation',
                    allocationType: 'Allocation Type',
                    allocate: 'Allocate',
                    deallocate: 'Deallocate',
                    selected: 'Selected',
                    filtered: 'Filtered',
                    screensToAllocate: 'Screens To Allocate',
                    allocateTo: 'Allocate to',
                    packs: '$t(CommonWords.Pack)s',
                    tags: 'Tags',
                    empty: 'Please Select...',
                    submitBtn: 'Assign',
                    allowOverride: 'Allow  Override',
                    conflicts:
                        'Some of the screens are already assigned a different "{{name}}". Please Override or unselect them.',
                    selectAll: 'Select All Below',
                    deselectAll: 'Deselect All Below',
                    viewSelected: 'View Selected',
                    viewAll: 'View All',
                    override: 'Override',
                    back: 'Check selected',
                },
                table: {
                    allocation: 'Selected Screens',
                    idCol: 'ID',
                    addressCol: 'Address',
                    uIdCol: 'UID',
                    frameID: '$t(CommonWords.Frame) ID',
                    mediaOwners: '$t(CommonWords.MediaOwners)',
                    conurbation: '$t(CommonWords.Conurbation)',
                    animation: 'Animation',
                    resolution: 'Resolution',
                    size: 'Size',
                    slot: 'Slot',
                    environment: 'Environment',
                    brochure: 'Brochure',
                    updateBrochure: 'Update Brochure',
                    deleteBrochure: 'Delete Brochure',
                    confirmation: 'Are you sure you want to delete this brochure',
                    deletedBrochure: 'Brochure has been deleted',
                    yes: 'Yes',
                    no: 'No',
                    deleteDisplay: 'Delete Display',
                    pack: '$t(CommonWords.Pack)',
                    tag: 'Tag',
                    status: 'Status',
                    editDisplay: 'Edit Display',
                    actions: 'Actions',
                },
            },
            OwnerList: {
                metaTitle: '$t(CommonWords.Owner)',
                description: '$t(CommonWords.Owner)',
                createBtn: 'Create $t(CommonWords.Owner)',
                submit: 'Submit',
                buttonForm: {
                    name: 'Name',
                    partner: "Vendor's Name",
                    markets: 'Markets',
                    countries: 'Countries',
                },
                table: {
                    id: 'ID',
                    partner: 'Vendor',
                    extId: 'External ID',
                    ownerId: '$t(CommonWords.Owner) ID',
                    name: 'Name',
                    uId: 'UID',
                    packs: '$t(CommonWords.Pack)s',
                    countries: 'Countries',
                    deleteOwner: 'Delete $t(CommonWords.Owner)',
                    displays: 'Displays',
                    market: 'Market',
                    actions: 'Enable/Disable $t(CommonWords.Owner)',
                    editOwner: 'Edit Owner',
                },
            },
            CountriesList: {
                metaTitle: 'Countries',
                description: 'Countries',
                createBtn: 'Create Country',
                submitBtn: 'Submit',
                table: {
                    id: 'ID',
                    extId: 'External ID',
                    name: 'Name',
                    alpha2: 'Alpha 2 Code',
                    alpha3: 'Alpha 3 Code',
                    numericCode: 'Numeric Code',
                    currency: 'Currency',
                },
            },
            DisplaysCountryEdit: {
                metaTitle: 'Countries Edit',
                description: 'Countries Edit',
                submitButton: 'Submit',
                success: 'Country successfully edited',
                fields: {
                    id: 'ID',
                    owners: '$t(CommonWords.Owners)',
                    status: 'Status',
                    name: 'Name',
                    alpha2: 'Alpha 2 Code',
                    alpha3: 'Alpha 3 Code',
                    numericCode: 'Numeric Code',
                    currency: 'Currency',
                    active: 'Active',
                    inactive: 'Inactive',
                },
            },
            MarketsList: {
                metaTitle: 'Markets',
                description: 'Markets',
                createBtn: 'Create Market',
                table: {
                    id: 'ID',
                    name: 'Name',
                    uId: 'Unilive ID',
                    country: 'Country',
                    currency: 'Currency',
                },
            },
            DisplaysMarketEdit: {
                metaTitle: 'Edit Market',
                description: 'Edit Market',
                submit: 'Submit',
                success: 'Market successfully edited',
                fields: {
                    country: 'Country',
                    name: 'Name',
                    owners: '$t(CommonWords.Owners)',
                    logo: 'Logo',
                    status: 'Status',
                    active: 'Active',
                    inactive: 'Inactive',
                },
            },
            Packs: {
                metaTitle: '$t(CommonWords.Pack)s',
                description: '$t(CommonWords.Pack)s',
                createBtn: 'Create $t(CommonWords.Pack)',
                submitBtn: 'Submit',
                table: {
                    id: 'ID',
                    resolutionCol: 'Resolutions',
                    uIdCol: 'UID',
                    extIdCol: 'External ID',
                    marketCol: 'Market ID',
                    nameCol: 'Name',
                    sizeCol: 'Size',
                    displaysCol: 'Displays',
                    brochure: 'Brochure',
                    deletePack: 'Delete $t(CommonWords.Pack)',
                    editPack: 'Edit $t(CommonWords.Pack)',
                    ownerName: '$t(CommonWords.Owner) Name',
                },
            },
            Analogue: {
                metaTitle: 'Analogues',
                description: 'Analogues',
                createBtn: 'Create Analogue Screen',
                submitBtn: 'Submit',
                table: {
                    id: 'ID',
                    extIdCol: 'External ID',
                    nameCol: 'Name',
                    marketCol: 'Market',
                    sizeCol: 'Size',
                    owner: '$t(CommonWords.Owner)',
                    resolution: 'Resolution',
                    description: 'Description',
                },
            },
            AnalgueEdit: {
                metaTitle: 'Analogue Edit',
                description: 'Analogue Edit',
                submitButton: 'Submit',
                success: 'Analogue successfully edited',
                fields: {
                    status: 'Status',
                    active: 'Active',
                    inactive: 'Inactive',
                    country: 'Country',
                    market: 'Market',
                    owner: '$t(CommonWords.Owner)',
                    description: 'Description',
                    name: 'Name',
                    size: 'Size',
                    resolution: 'Resolution',
                    env: 'Environment',
                },
            },
            Tags: {
                metaTitle: 'Tags',
                description: 'Tags',
                createBtn: 'Create Tag',
                submit: 'Submit',
                buttonForm: {
                    name: 'Name',
                    market: 'Market',
                    partner: 'Vendor',
                    parent: 'Parent',
                    desc: 'Description',
                    operational: 'Operational',
                    status: 'Status',
                    active: 'Active',
                    inactive: 'Inactive',
                    yes: 'Yes',
                    no: 'No',
                },
                table: {
                    id: 'ID',
                    extIdCol: 'External ID',
                    nameCol: 'Name',
                    operationalCol: 'Operational',
                    linkedCol: 'Linked',
                    displaysCol: 'Displays',
                    dwot: 'Displays Without Operational Times',
                    yes: 'Yes',
                    no: 'No',
                    deleteTag: 'Delete Tag',
                    editTag: 'Edit Tag',
                },
            },
        },
    },
    Component: {
        CopyButton: {
            copied: 'Copied!',
        },
        LogFileParsedDaily: {
            groupByHourCheckbox: 'Hourly Breakdown',
            groupByMediaCheckbox: 'Group By Media Name',
            totalsRow: 'Totals',
            timePlayedTotal: 'Played: {{hours}} hours, {{minutes}} minutes, {{seconds}} seconds',
        },
    },
    PasswordStrength: {
        minLength: 'Must be at least {{minLength}} characters long',
        match: 'Both passwords must match',
        number: 'Needs a number',
        case: 'At least 1 upper case & 1 lower case',
        specialChar: 'At least 1 or more special characters',
    },
    SubmitButton: {
        defaultLabel: 'Save',
        defaultDeleteLabel: 'Delete',
    },
    Dialog: {
        defaultCloseLabel: 'Close',
        defaultCancelLabel: 'Cancel',
        defaultSubmitLabel: 'Submit',
        defaultDeleteLabel: 'Delete',
    },
    Pagination: {
        resetFilters: 'Reset',
        searchPlaceholder: 'Search',
        moreCount: '{{count}} More',
        moreDialogDefaultTitle: 'All',

        filterByCategoriesLabel: 'Filter by categories: {{filters}}',
        advancedFiltersLabel: 'Advanced Filters',

        dateRange: {
            clear: 'Clear',
        },

        filterLabels: {
            Campaign: {
                'filter.buyer.id': 'Buyer',
                'filter.client.id': 'Brand',
                'filter.market.id': 'Market',
            },
            Displays_LineItem: {
                'filter.type': 'Type',
                'filter.display_type': 'Display Type',
                'filter.environment': 'Environment',
                'filter.animation': 'Animation',
                'filter.filetypes': 'File Types',
                'filter.fileTypes': 'File Types',
                'filter.resolution': 'Resolution (W x H)',
                'filter.size': 'Format',
                'filter.slot_length': 'Slot Length',
                'filter.slots': 'Slots',
                'filter.owner.id': '$t(CommonWords.Owner)',
                'filter.market.id': 'Market',
            },
        },
    },
    SystemSearch: {
        filters: 'Filters',
        recentPages: 'Recent Pages',
        recentPagesClearAll: 'Clear History',
        otherColumn: 'Recent Something Else',
        defaultSearchPlaceholder: 'System Search...',
        errorSearching: 'error searching',
        noResults: 'no results',
        impersonatingRecentPagesDisabled: '-- Recent Pages disabled while impersonating a user --',
        aliases: {
            dashboardPage: ['Dashboard Home', 'Home Page'],
            newCampaignPage: ['New Campaign', 'Create Campaign', 'Add Campaign'],
            dataStatusPage: ['Data Status', 'Campaign Data Status'],
            campaignListPage: ['Campaign List', 'Campaign Search'],
            agencyListPage: ['Search Agencies', 'Agency List'],
            buyerListPage: ['Search Buyers', 'Buyer List'],
            clientListPage: ['Search Brands', 'Brand List'],
            ownerListPage: ['Search $t(CommonWords.Owners)', '$t(CommonWords.Owner) List'],
            creativesSearchPage: ['Search Creatives', 'Creatives List', 'Creatives Search'],
            adminHomePage: ['Admin', 'Admin Home'],
            skinListPage: ['Skins', 'Skins List'],
            marketListPage: ['Markets', 'Markets List'],
            roleListPage: ['Roles', 'Roles List'],
            userListPage: ['Users', 'Users List', 'User List', 'User Management'],
            myProfilePage: ['Profile', 'Update Password', 'My Profile', 'Change Password'],
            impersonatePage: ['Impersonate User', 'Help User', 'Impersonate'],
            frameSearchPage: ['$t(CommonWords.Frame) Search'],
            newUser: ['Create User', 'New User', 'Add User'],

            popDisplaysAdmin: ['Pop Displays Admin', 'Pop Displays Hone'],
            popDisplaysTaskQueue: ['Job Status', 'Task Queue', 'Task List'],
            popDisplaysLogFileImportList: [
                'Log File Injestion Explorer',
                'Log Files',
                'Log Files List',
                'Log File Import List',
            ],
            popDisplaysPlayoutExport: ['Playout Export'],
            popDisplaysVirtualCampaignBuilder: ['Virtual Campaign Builder'],
            popDisplaysLogImportSourceList: ['Log Import Sources', 'Log File Import Sources'],
            popDisplaysDisplayList: [
                'Pop Displays List',
                'Displays',
                'Displays List',
                'Display Explorer',
            ],
            popDisplaysChannelFrameList: [
                'Pop Channel $t(CommonWords.Frame)s List',
                'Channel $t(CommonWords.Frame)s',
                'Channel $t(CommonWords.Frame)s List',
                'Channel Explorer',
            ],
            popDisplaysScheduleTemplates: ['Schedule Templates'],

            displaysAdmin: ['Displays Admin', 'Displays Home'],
            displaysAdminDisplayList: ['Displays List', 'Displays Search'],
            displaysOwnerDisplayList: [
                '$t(CommonWords.Owner) List',
                '$t(CommonWords.Owner) Search',
            ],
            packsAdminDisplayList: ['$t(CommonWords.Pack)s List', '$t(CommonWords.Pack)s Search'],
            displaysAnalogueDisplayList: ['Analogues List', 'Analogues Search'],
            tagsDisplayList: ['Tags List', 'Tags Search'],
            displaysCountriesList: ['Countries List', 'Countries Search'],
            displaysMarketsList: ['Markets List', 'Markets Search'],
        },
        prefixes: {
            agency: 'ag',
            buyer: 'buy',
            owner: 'own',
            campaign: 'cam',
            skin: 'skin',
            user: 'user',
            popdisplay: 'disp',
        },
        prefixDescriptions: {
            agency: 'agency name',
            buyer: 'buyer name',
            owner: '$t(CommonWords.Owner) name',
            campaign: 'campaign name',
            skin: 'skin name',
            user: 'user name',
            popdisplay: 'display name',
        },
    },
    Meta: {
        pageTitlePrefix: 'UniLIVE | ',
        footCopyrightCompany: 'UniLIVE',
        footerPoweredBy: 'Powered by',
    },
    Reporting: {
        noData: 'No Data',
        timeframes: {
            timeframeDaily: 'Daily',
            timeframeWeekly: 'Weekly',
            timeframeBiWeekly: 'Bi-Weekly',
            timeframeMonthly: 'Monthly',
            timeframeRange: 'Date Range',
            timeframeAll: 'All',
            timeFrameAllLabel: 'All time',
        },
        ticks: {
            hoursLabel: '{{count}} hrs',
            minutesLabel: '{{count}} mins',
            secondsLabel: '{{count}} secs',
        },
        exporting: {
            exportSubmit: 'Export Image',
            exportSettings: 'Configuration',
            exportData: 'Export Data',
            exportSubmitLoading: 'Loading',

            form: {
                resetFormLabel: 'Reset Configuration',
                widthLabel: 'Width',
                heightLabel: 'Height',
                imageTypeLabel: 'Image Type',
                showLegendLabel: 'Show Legend',

                marginHeader: 'Margin',
                marginTopLabel: 'Top',
                marginRightLabel: 'Right',
                marginBottomLabel: 'Bot',
                marginLeftLabel: 'Left',

                xAxisHeader: 'X-Axis',
                xAxisAngleLabel: 'Text Angle (-360 -> 360)',
                xAxisAllLabels: 'All Labels',
                xAxisTextAnchor: 'Text Anchor',
                xAxisDateFormat: 'Date Format',
                xAxisDateFormatDocs: 'Format Docs',

                textAnchor: {
                    [ChartLabelAnchor.Start]: 'Start',
                    [ChartLabelAnchor.Middle]: 'Middle',
                    [ChartLabelAnchor.End]: 'End',
                },

                segmentsHeader: 'Segments',
                segmentsSortBy: 'Sort By',
                segmentsReverse: 'Reverse',
                segmentsResetOrder: 'Initial',
                segmentsHideAll: 'Hide All',
                segmentsShowAll: 'Show All',
                segmentsToggleHidden: 'Toggle Hidden',
                segmentSearchPlaceholder: 'Search...',
            },
        },
    },
    Duration: {
        hours_one: '{{count}} hr',
        hours_other: '{{count}} hrs',
        minutes_one: '{{count}} min',
        minutes_other: '{{count}} mins',
        seconds_one: '{{count}} sec',
        seconds_other: '{{count}} secs',
    },
    Weekdays: {
        /**
         * This is relied upon for ordering weekdays
         */
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
    },
    WeekdaysShort: {
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
        7: 'Sun',
    },
    Calendar: {},
    Currency: {
        symbol: '£',
    },
    Forms: {
        autocomplete: {
            defaultOpenPlaceholder: 'Type to Search... (`esc` to close)',
            selectAllOptionLabel: 'Select All',
        },
    },
    FileBrowser: {
        orderBy: 'Order By',
        orderByName: 'Name',
        orderByUpload: 'Upload Date',
        orderByAspectRatio: 'Aspect Ratio',
        filePreview: {
            openInTab: 'Open In New Tab',
            download: 'Download',
            expand: 'Expand',
            previewModelClose: 'Close',
            searchLabel: 'Search...',
            showCount: '{{count}} of {{total}}',
            resolutionDropdownLabel: 'Filter by resolution (W x H)',
            aspectRatioDropdownLabel: 'Filter by aspect ratio',
            fileTypeDropdownLabel: 'Filter by file type',
            fileDuration: 'Duration',
        },
    },
    Models: {
        Campaign: {
            status: {
                [CampaignStatus.CampaignStarted]: 'Campaign Started',
                [CampaignStatus.CreativesUploaded]: 'Creatives Uploaded',
                [CampaignStatus.CampaignReady]: 'Campaign Ready',
                [CampaignStatus.CampaignArchived]: 'Campaign Archived',
                [CampaignStatus.CampaignComplete]: 'Campaign Complete',
                [CampaignStatus.Unknown]: 'Unknown',
            },
        },
        DisplaysPack: { status: { 0: '$t(CommonWords.Pack)', 1: 'Site' } },
        Displays_LineItem: {
            type: {
                [Displays_LineItemLineItemType.Digital]: 'Display',
                [Displays_LineItemLineItemType.Package]: '$t(CommonWords.Pack)',
                [Displays_LineItemLineItemType.SiteGroup]: 'Displays',
                [Displays_LineItemLineItemType.Analogue]: 'Classic',
                [Displays_LineItemLineItemType.Logo]: 'Logo Box',
            },
        },
        Alert: {
            startTime: 'Start: {{start}}',
        },
    },
    BadRequestPage: {
        back: 'Back to home',
        status: {
            404: 'Page Not Found',
            400: 'Forbidden',
            403: 'Forbidden',
            500: 'An error has occurred which has been reported to the development team.',
        },
    },
};

export default en;
