import React, { useCallback, useMemo, useState } from 'react';
import { useAPIClientRequest, useSkinSwitcher } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import AdminSkinSwitcher from 'c-wrapper/Components/SkinLangSwitcher/AdminSkinSwitcher';
import { Alert, Button, DialogV2, LoadingSpinner, OptionSchema } from 'c-components';
import { DEFAULT_SKIN_ID } from 'c-wrapper/Slices/UI/ui-selectors';
import { useCommonTranslation } from 'c-translation';

import { Stack } from '@mui/material';
import { NetworkRequestState } from 'c-data-layer';

type BooleanState = {
    value: boolean;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
};
interface PcaDialogProps {
    id: string | number;
    pcaState: BooleanState;
    isVistar: boolean;
}
const PptPCADialog: React.FC<PcaDialogProps> = ({ id, pcaState, isVistar }) => {
    const t = useCommonTranslation();
    const { selectedSkin } = useSkinSwitcher();
    const [skin, setSkin] = useState<number | null>(selectedSkin?.id);
    const { start, data, isLoading, error, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.campaignPowerpoint,
    );
    const additionalOptions = useMemo<OptionSchema[]>(
        () => [
            {
                value: DEFAULT_SKIN_ID,
                label: t('Modules.Admin.SkinSwitcher.defaultThemeOptionLabel'),
            },
        ],
        [t],
    );
    const reportType = useMemo(() => (isVistar ? 'DSP_report' : 'Linear_report'), [isVistar]);
    const onSkinSelected = useCallback((val: number) => {
        setSkin(val);
    }, []);
    const onSubmit = useCallback(() => {
        start(id, reportType, skin);
    }, [id, reportType, skin, start]);
    return (
        <>
            <DialogV2
                open={pcaState.value}
                onClose={pcaState.setFalse}
                title="Modules.Main.Campaigns.Overview.Pca.title"
            >
                <Stack>
                    <AdminSkinSwitcher
                        disabled={isLoading}
                        onChange={onSkinSelected}
                        selectedSkinId={skin ?? DEFAULT_SKIN_ID}
                        additionalOptions={additionalOptions}
                    />
                </Stack>
                <Button onClick={onSubmit} disabled={isLoading}>
                    {t('Modules.Main.Campaigns.Overview.Pca.submit')}
                </Button>
                {isLoading && <LoadingSpinner />}
                {error && <Alert severity="error">{String(error)}</Alert>}
                {requestState === NetworkRequestState.Success && (
                    <Alert severity="success">
                        {t('Modules.Main.Campaigns.Overview.Pca.success')}
                    </Alert>
                )}
            </DialogV2>
        </>
    );
};

export default PptPCADialog;
