import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { TabWrapperSettings } from 'c-types';

import { ClearIcon } from '@mui/x-date-pickers-pro';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { TabsWrapper } from 'c-components';

interface ReportingTableProps {
    type: 'vistar' | 'geopath';
    id: number | string;
}
const non_geopath_data = [
    {
        'Display Owner': 'Zoom Media (USA)',
        'Display Format': 'Health and Beauty|Gyms',
        Plays: 420911,
        'DSP Impressions': 13587904.31, // audience recieved
        'DSP IM': 32.28, // dspim
        'Average IM': 29.01,
        'IM VAR': -3.27, // variance
        '% VAR': '-10.13%',
    },
    {
        'Display Owner': 'GSTV',
        'Display Format': 'Retail|Gas Stations',
        Plays: 9774377,
        'DSP Impressions': 12259057.68,
        'DSP IM': 1.25,
        'Average IM': 1.26,
        'IM VAR': 0.01,
        '% VAR': '0.80%',
    },
    {
        'Display Owner': 'Volta Charging',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 9880531,
        'DSP Impressions': 5346255.312,
        'DSP IM': 0.54,
        'Average IM': 0.85,
        'IM VAR': 0.31,
        '% VAR': '57.41%',
    },
    {
        'Display Owner': 'International Outdoor',
        'Display Format': 'Outdoor|Billboards',
        Plays: 138325,
        'DSP Impressions': 4839112.093,
        'DSP IM': 34.98,
        'Average IM': 19.71,
        'IM VAR': -15.27,
        '% VAR': '-43.65%',
    },
    {
        'Display Owner': 'Screenverse',
        'Display Format': 'Offices|Office Buildings',
        Plays: 607020,
        'DSP Impressions': 3161569.25,
        'DSP IM': 5.21,
        'Average IM': 2.73,
        'IM VAR': -2.48,
        '% VAR': '-47.60%',
    },
    {
        'Display Owner': 'Captivate',
        'Display Format': 'Offices|Office Buildings',
        Plays: 314534,
        'DSP Impressions': 2603757.51,
        'DSP IM': 8.28,
        'Average IM': 2.73,
        'IM VAR': -5.55,
        '% VAR': '-67.03%',
    },
    {
        'Display Owner': 'Vengo Labs',
        'Display Format': 'Offices|Office Buildings',
        Plays: 1639311,
        'DSP Impressions': 2463938.197,
        'DSP IM': 1.5,
        'Average IM': 2.73,
        'IM VAR': 1.23,
        '% VAR': '82.00%',
    },
    {
        'Display Owner': 'Adams',
        'Display Format': 'Outdoor|Billboards',
        Plays: 153107,
        'DSP Impressions': 2049724.64,
        'DSP IM': 13.39,
        'Average IM': 19.71,
        'IM VAR': 6.32,
        '% VAR': '47.20%',
    },
    {
        'Display Owner': 'Smartify Media',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 143097,
        'DSP Impressions': 1604584,
        'DSP IM': 11.21,
        'Average IM': 0.85,
        'IM VAR': -10.36,
        '% VAR': '-92.42%',
    },
    {
        'Display Owner': 'Blip Billboards',
        'Display Format': 'Outdoor|Billboards',
        Plays: 93857,
        'DSP Impressions': 1248875.045,
        'DSP IM': 13.31,
        'Average IM': 19.71,
        'IM VAR': 6.4,
        '% VAR': '48.08%',
    },
    {
        'Display Owner': 'Orange Barrel Media',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 654493,
        'DSP Impressions': 1237208.25,
        'DSP IM': 1.89,
        'Average IM': 0.85,
        'IM VAR': -1.04,
        '% VAR': '-55.03%',
    },
    {
        'Display Owner': 'Corner Media',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 142360,
        'DSP Impressions': 708054.38,
        'DSP IM': 4.97,
        'Average IM': 0.85,
        'IM VAR': -4.12,
        '% VAR': '-82.90%',
    },
    {
        'Display Owner': 'Lamar',
        'Display Format': 'Outdoor|Billboards',
        Plays: 55603,
        'DSP Impressions': 550211.4725,
        'DSP IM': 9.9,
        'Average IM': 19.71,
        'IM VAR': 9.81,
        '% VAR': '99.09%',
    },
    {
        'Display Owner': 'Vertical Impression - US',
        'Display Format': 'Offices|Office Buildings',
        Plays: 357215,
        'DSP Impressions': 468408,
        'DSP IM': 1.31,
        'Average IM': 2.73,
        'IM VAR': 1.42,
        '% VAR': '108.40%',
    },
    {
        'Display Owner': 'Gabi Media',
        'Display Format': 'Offices|Office Buildings',
        Plays: 416365,
        'DSP Impressions': 416365,
        'DSP IM': 1,
        'Average IM': 2.73,
        'IM VAR': 1.73,
        '% VAR': '173.00%',
    },
    {
        'Display Owner': 'RoveIQ',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 124187,
        'DSP Impressions': 316347.5578,
        'DSP IM': 2.55,
        'Average IM': 0.85,
        'IM VAR': -1.7,
        '% VAR': '-66.67%',
    },
    {
        'Display Owner': 'Atmosphere',
        'Display Format': 'Health and Beauty|Gyms',
        Plays: 26389,
        'DSP Impressions': 268889.4,
        'DSP IM': 10.19,
        'Average IM': 29.01,
        'IM VAR': 18.82,
        '% VAR': '184.69%',
    },
    {
        'Display Owner': 'Bulletin Displays',
        'Display Format': 'Outdoor|Billboards',
        Plays: 17527,
        'DSP Impressions': 266642.03,
        'DSP IM': 15.21,
        'Average IM': 19.71,
        'IM VAR': 4.5,
        '% VAR': '29.59%',
    },
    {
        'Display Owner': 'Orange Barrel Media',
        'Display Format': 'Outdoor|Billboards',
        Plays: 3686,
        'DSP Impressions': 154812,
        'DSP IM': 42,
        'Average IM': 19.71,
        'IM VAR': -22.29,
        '% VAR': '-53.07%',
    },
    {
        'Display Owner': 'Vengo Labs',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 135756,
        'DSP Impressions': 85252.98,
        'DSP IM': 0.63,
        'Average IM': 0.85,
        'IM VAR': 0.22,
        '% VAR': '34.92%',
    },
    {
        'Display Owner': 'Starlite Digital',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 52182,
        'DSP Impressions': 68065.88988,
        'DSP IM': 1.3,
        'Average IM': 0.85,
        'IM VAR': -0.45,
        '% VAR': '-34.62%',
    },
    {
        'Display Owner': 'Intersection',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 10020,
        'DSP Impressions': 48998.16682,
        'DSP IM': 4.89,
        'Average IM': 0.85,
        'IM VAR': -4.04,
        '% VAR': '-82.62%',
    },
    {
        'Display Owner': 'Liquid Outdoor',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 16219,
        'DSP Impressions': 32388.7988,
        'DSP IM': 2,
        'Average IM': 0.85,
        'IM VAR': -1.15,
        '% VAR': '-57.50%',
    },
    {
        'Display Owner': 'Shelf Nine',
        'Display Format': 'Retail|Gas Stations',
        Plays: 14862,
        'DSP Impressions': 29724,
        'DSP IM': 2,
        'Average IM': 1.26,
        'IM VAR': -0.74,
        '% VAR': '-37.00%',
    },
    {
        'Display Owner': 'Screenverse + Apparatix Billboards',
        'Display Format': 'Outdoor|Billboards',
        Plays: 1036,
        'DSP Impressions': 19931.14177,
        'DSP IM': 19.24,
        'Average IM': 19.71,
        'IM VAR': 0.47,
        '% VAR': '2.44%',
    },
    {
        'Display Owner': 'Vengo Labs',
        'Display Format': 'Health and Beauty|Gyms',
        Plays: 15312,
        'DSP Impressions': 19786.7,
        'DSP IM': 1.29,
        'Average IM': 29.01,
        'IM VAR': 27.72,
        '% VAR': '2148.84%',
    },
    {
        'Display Owner': 'Gabi Media',
        'Display Format': 'Health and Beauty|Gyms',
        Plays: 16115,
        'DSP Impressions': 16115,
        'DSP IM': 1,
        'Average IM': 29.01,
        'IM VAR': 28.01,
        '% VAR': '2801.00%',
    },
    {
        'Display Owner': 'Loop Media',
        'Display Format': 'Offices|Office Buildings',
        Plays: 1906,
        'DSP Impressions': 10176,
        'DSP IM': 5.34,
        'Average IM': 2.73,
        'IM VAR': -2.61,
        '% VAR': '-48.88%',
    },
    {
        'Display Owner': 'Screenverse',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 2357,
        'DSP Impressions': 5892.5,
        'DSP IM': 2.5,
        'Average IM': 0.85,
        'IM VAR': -1.65,
        '% VAR': '-66.00%',
    },
    {
        'Display Owner': 'Dover Fueling Solutions',
        'Display Format': 'Retail|Gas Stations',
        Plays: 1539,
        'DSP Impressions': 1540,
        'DSP IM': 1,
        'Average IM': 1.26,
        'IM VAR': 0.26,
        '% VAR': '26.00%',
    },
    {
        'Display Owner': 'True Sync Media',
        'Display Format': 'Offices|Office Buildings',
        Plays: 74,
        'DSP Impressions': 944.721,
        'DSP IM': 12.77,
        'Average IM': 12.77,
        'IM VAR': 0,
        '% VAR': '0.00%',
    },
    {
        'Display Owner': 'Fuel Media TV',
        'Display Format': 'Retail|Gas Stations',
        Plays: 240,
        'DSP Impressions': 515.30188,
        'DSP IM': 2.15,
        'Average IM': 1.26,
        'IM VAR': -0.89,
        '% VAR': '-41.40%',
    },
    {
        'Display Owner': 'Loop Media',
        'Display Format': 'Health and Beauty|Gyms',
        Plays: 111,
        'DSP Impressions': 460,
        'DSP IM': 4.14,
        'Average IM': 29.01,
        'IM VAR': 24.87,
        '% VAR': '600.72%',
    },
];

const geopathData = [
    {
        'Display Owner': 'Outfront Media',
        'Display Format': 'Outdoor|Billboards',
        Plays: 741469,
        'DSP Impressions': 14991906.35,
        'Geopath Impressions': 14571020.67,
        VAR: -420885.68,
        'DSP IM': 20.21919508,
        'Geopath IM': 19.65155748,
        'IM VAR': -0.5676376,
        '% VAR': '-2.81%',
    },
    {
        'Display Owner': 'Clear Channel',
        'Display Format': 'Outdoor|Billboards',
        Plays: 309242,
        'DSP Impressions': 12787591.09,
        'Geopath Impressions': 12476920.58,
        VAR: -310670.51,
        'DSP IM': 41.3514047,
        'Geopath IM': 40.3467853,
        'IM VAR': -1.0046194,
        '% VAR': '-2.43%',
    },
    {
        'Display Owner': 'Lamar',
        'Display Format': 'Outdoor|Billboards',
        Plays: 288023,
        'DSP Impressions': 4914640.327,
        'Geopath Impressions': 3830365.328,
        VAR: -1084274.999,
        'DSP IM': 17.06336066,
        'Geopath IM': 13.29881755,
        'IM VAR': -3.76454311,
        '% VAR': '-22.06%',
    },
    {
        'Display Owner': 'Orange Barrel Media',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 650627,
        'DSP Impressions': 592867.48,
        'Geopath Impressions': 1303037.766,
        VAR: 710170.286,
        'DSP IM': 0.911224834,
        'Geopath IM': 2.002741611,
        'IM VAR': 1.091516777,
        '% VAR': '119.79%',
    },
    {
        'Display Owner': 'Intersection Media',
        'Display Format': 'Outdoor|Urban Panels',
        Plays: 257829,
        'DSP Impressions': 488033.276,
        'Geopath Impressions': 339148.5641,
        VAR: -148884.7119,
        'DSP IM': 1.892856413,
        'Geopath IM': 1.315401154,
        'IM VAR': -0.577455259,
        '% VAR': '-30.51%',
    },
    {
        'Display Owner': 'WOW Media',
        'Display Format': 'Outdoor|Billboards',
        Plays: 4564,
        'DSP Impressions': 81668.96305,
        'Geopath Impressions': 399898.584,
        VAR: 318229.621,
        'DSP IM': 17.89416368,
        'Geopath IM': 87.62019807,
        'IM VAR': 69.72603439,
        '% VAR': '389.66%',
    },
    {
        'Display Owner': 'Big Outdoor',
        'Display Format': 'Outdoor|Billboards',
        Plays: 369,
        'DSP Impressions': 17158.0602,
        'Geopath Impressions': 15275.75624,
        VAR: -1882.30396,
        'DSP IM': 46.49880813,
        'Geopath IM': 41.39771339,
        'IM VAR': -5.10109474,
        '% VAR': '-10.97%',
    },
];

const getFilteredCountsExcluding = (data, filters, filterKeyToExclude, topBottomFilter) => {
    let filteredDataWithoutSpecificFilter = data.filter(row =>
        Object.entries(filters).every(
            ([key, values]) =>
                key === filterKeyToExclude ||
                (values as string[]).length === 0 ||
                (values as string[]).includes(row[key]),
        ),
    );

    if (topBottomFilter !== 'none' && topBottomFilter != null) {
        filteredDataWithoutSpecificFilter = filteredDataWithoutSpecificFilter.filter(row => {
            const normalizedValue = normalizeValue(row);
            return (
                (topBottomFilter === 'top' && normalizedValue > 0) ||
                (topBottomFilter === 'bottom' && normalizedValue < 0)
            );
        });
    }

    const counts = filteredDataWithoutSpecificFilter.reduce((acc, row) => {
        const key = row[filterKeyToExclude];
        if (!acc[key]) {
            acc[key] = 0;
        }
        acc[key] += 1;
        return acc;
    }, {});

    return Object.entries(counts).map(([key, count]) => ({
        key,
        count,
    }));
};

const parsePercentage = percentageString => {
    if (percentageString == null) return 0; // Handle null or undefined values
    const stringified = String(percentageString); // Ensure it's a string
    const trimmedString = stringified.replace('%', '').trim();
    const parsed = parseFloat(trimmedString);
    return parsed;
};

const normalizeValue = row => parsePercentage(row['% VAR']) / 100;

const determineColor = (value, theme) =>
    value === 0 ? 'black' : value > 0 ? theme.palette.success.main : theme.palette.error.main;

const ReportingTable: React.FC<ReportingTableProps> = ({ type, id }) => {
    const t = useCommonTranslation();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        'Display Owner': [],
        'Display Format': [],
    });
    const [originalRowsPerPage, setOriginalRowsPerPage] = useState(25);
    const [uniqueDisplayOwners, setUniqueDisplayOwners] = useState([]);
    const [uniqueDisplayFormats, setUniqueDisplayFormats] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [topBottomFilter, setTopBottomFilter] = useState(null);
    const [topBottomCount, setTopBottomCount] = useState(5);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState('DSP Impressions');
    const topBottomToggled = useBoolean(false);
    const { start } = useAPIClientRequest(ApiClient.Reports.impressionMultipliers);
    const { start: geoStart } = useAPIClientRequest(ApiClient.Reports.geopathImpressionMultipliers);

    const getData = useCallback(async () => {
        if (type === 'geopath' && id === 9464) {
            const formattedData = geopathData.map(row => ({
                ...row,
                '% VAR': parsePercentage(row['% VAR']),
            }));
            setData(formattedData);
            setFilteredData(formattedData);
            const displayOwners = [...new Set(formattedData.map(row => row['Display Owner']))];
            const displayFormats = [...new Set(formattedData.map(row => row['Display Format']))];
            setUniqueDisplayOwners(displayOwners);
            setUniqueDisplayFormats(displayFormats);
            return;
        }
        if (type === 'vistar' && id === 9464) {
            const formattedData = non_geopath_data.map(row => ({
                ...row,
                '% VAR': parsePercentage(row['% VAR']),
            }));
            setData(formattedData);
            setFilteredData(formattedData);
            const displayOwners = [...new Set(formattedData.map(row => row['Display Owner']))];
            const displayFormats = [...new Set(formattedData.map(row => row['Display Format']))];
            setUniqueDisplayOwners(displayOwners);
            setUniqueDisplayFormats(displayFormats);
            return;
        }
        if (type === 'vistar') {
            const response = await start({ id });
            if (response?.data) {
                const dataReformatted = (response as any).data.map(row => ({
                    'Display Owner': row.display_owner,
                    'Display Format': row.display_format,
                    Plays: row.sum_of_play,
                    'DSP Impressions': row.dsp_impressions,
                    'DSP IM': row.dsp_avg_impressions,
                    'Average IM': row.average_im,
                    'IM VAR': row.variance,
                    '% VAR': row.variance_percent * 100,
                }));
                setData(dataReformatted);
                setFilteredData(dataReformatted);
                const displayOwners = [
                    ...new Set(dataReformatted.map(row => row['Display Owner'])),
                ];
                const displayFormats = [
                    ...new Set(dataReformatted.map(row => row['Display Format'])),
                ];
                setUniqueDisplayOwners(displayOwners);
                setUniqueDisplayFormats(displayFormats);
            }
        }
        if (type === 'geopath') {
            const response = await geoStart({ id });
            if (response?.data) {
                const formattedData = (response as any).data.map(row => ({
                    'Display Owner': row.display_owner,
                    'Display Format': row.display_format,
                    Plays: row.sum_of_play,
                    'DSP Impressions': row.dsp_impressions,
                    'Geopath Impressions': row.geopath_impressions,
                    VAR: row.variance,
                    'DSP IM': row.dsp_avg_impressions,
                    'Geopath IM': row.geopath_avg_impressions,
                    'IM VAR': row.im_variance,
                    '% VAR': row.variance_percent * 100,
                }));
                setData(formattedData);
                setFilteredData(formattedData);
                const displayOwners = [...new Set(formattedData.map(row => row['Display Owner']))];
                const displayFormats = [
                    ...new Set(formattedData.map(row => row['Display Format'])),
                ];
                setUniqueDisplayOwners(displayOwners);
                setUniqueDisplayFormats(displayFormats);
            }
        }
    }, [geoStart, id, start, type]);

    useEffect(() => {
        getData();
    }, [getData]);
    useEffect(() => {
        if (topBottomFilter === 'none') {
            setTopBottomFilter(null);
        }
    }, [topBottomFilter]);

    useEffect(() => {
        let tempData = data.filter(row =>
            Object.entries(filters).every(
                ([key, values]) => values.length === 0 || values.includes(row[key]),
            ),
        );

        if (topBottomFilter !== 'none' && topBottomFilter != null) {
            tempData = tempData
                .filter(row => {
                    const normalizedValue = normalizeValue(row);
                    return (
                        (topBottomFilter === 'top' && normalizedValue > 0) ||
                        (topBottomFilter === 'bottom' && normalizedValue < 0)
                    );
                })
                .sort(
                    (a, b) => parseFloat(b['DSP Impressions']) - parseFloat(a['DSP Impressions']),
                );
            if (topBottomToggled.value) {
                setTopBottomCount(tempData.length + 1);
                setRowsPerPage(tempData.length + 1);
                tempData = tempData.slice(0, topBottomCount);
            } else {
                tempData = tempData.slice(0, topBottomCount);
            }
            topBottomToggled.setFalse();
        } else {
            setRowsPerPage(originalRowsPerPage);
        }

        if (orderBy) {
            tempData.sort((a, b) => {
                if (orderBy === 'Variance') {
                    const valueA = parsePercentage(a['% VAR']) / 100;
                    const valueB = parsePercentage(b['% VAR']) / 100;
                    if (order === 'asc') {
                        return valueA - valueB;
                    }
                    return valueB - valueA;
                }
                if (orderBy === 'Display Owner' || orderBy === 'Display Format') {
                    const valueA = a[orderBy].toLowerCase();
                    const valueB = b[orderBy].toLowerCase();
                    if (order === 'asc') {
                        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                    }
                    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
                }
                const valueA = parseFloat(a[orderBy]);
                const valueB = parseFloat(b[orderBy]);
                if (order === 'asc') {
                    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                }
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            });
        }

        setFilteredData(tempData);
    }, [
        filters,
        data,
        topBottomFilter,
        topBottomCount,
        order,
        orderBy,
        topBottomToggled,
        type,
        originalRowsPerPage,
    ]);

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value,
        });
    };

    const handleTopBottomFilterChange = event => {
        const newFilter = event.target.value;

        if (newFilter !== 'none' && newFilter != null) {
            setFilters({
                'Display Owner': [],
                'Display Format': [],
            });
        }

        setTopBottomFilter(newFilter);

        if (newFilter !== 'none' && newFilter != null) {
            setOriginalRowsPerPage(rowsPerPage);
        }

        topBottomToggled.setTrue();
    };

    const handleTopBottomCountChange = event => {
        const newCount = parseInt(event.target.value, 10);
        setTopBottomCount(newCount);
        setRowsPerPage(newCount);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = property => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headers = {
        'Display Owner': t('Modules.Main.Campaigns.Overview.dsp.displayOwner'),
        'Display Format': t('Modules.Main.Campaigns.Overview.dsp.displayFormat'),
        Plays: t('Modules.Main.Campaigns.Overview.dsp.plays'),
        'DSP Impressions': t('Modules.Main.Campaigns.Overview.dsp.dspImpressions'),
        'Geopath Impressions': t('Modules.Main.Campaigns.Overview.dsp.geopathImpressions'),
        'DSP IM': t('Modules.Main.Campaigns.Overview.dsp.dspIm'),
        'Average IM': t('Modules.Main.Campaigns.Overview.dsp.averageFormatIm'),
        'Geopath IM': t('Modules.Main.Campaigns.Overview.dsp.geopathIm'),
        Variance: t('Modules.Main.Campaigns.Overview.dsp.var'),
        VAR: t('Modules.Main.Campaigns.Overview.dsp.var2'),
        'IM VAR': t('Modules.Main.Campaigns.Overview.dsp.imVar'),
    };

    const displayedHeaders =
        type === 'geopath'
            ? {
                  'Display Owner': headers['Display Owner'],
                  'Display Format': headers['Display Format'],
                  Plays: headers.Plays,
                  'DSP Impressions': headers['DSP Impressions'],
                  'Geopath Impressions': headers['Geopath Impressions'],
                  VAR: headers.VAR,
                  'DSP IM': headers['DSP IM'],
                  'Geopath IM': headers['Geopath IM'],
                  'IM VAR': headers['IM VAR'],
                  Variance: headers.Variance,
              }
            : {
                  'Display Owner': headers['Display Owner'],
                  'Display Format': headers['Display Format'],
                  Plays: headers.Plays,
                  'DSP Impressions': headers['DSP Impressions'],
                  'DSP IM': headers['DSP IM'],
                  'Average IM': headers['Average IM'],
                  'IM VAR': headers['IM VAR'],
                  Variance: headers.Variance,
              };

    const theme = useTheme();

    const rowsPerPageOptions = useMemo(() => {
        const options = [5, 10, 25, data.length];

        if (rowsPerPage <= data.length) {
            options.push(rowsPerPage);
        }

        const uniqueOptions = [...new Set(options.filter(value => value <= data.length))];

        return uniqueOptions.sort((a, b) => a - b);
    }, [rowsPerPage, data.length]);

    const displayOwnerCounts = useMemo(() => {
        const counts = getFilteredCountsExcluding(data, filters, 'Display Owner', topBottomFilter);
        return uniqueDisplayOwners.reduce((acc, owner) => {
            const count = counts.find(entry => entry.key === owner)?.count || 0;
            acc[owner] = count;
            return acc;
        }, {});
    }, [data, filters, topBottomFilter, uniqueDisplayOwners]);

    // Generates item counts for Display Formats
    const displayFormatCounts = useMemo(() => {
        const counts = getFilteredCountsExcluding(data, filters, 'Display Format', topBottomFilter);
        return uniqueDisplayFormats.reduce((acc, format) => {
            const count = counts.find(entry => entry.key === format)?.count || 0;
            acc[format] = count;
            return acc;
        }, {});
    }, [data, filters, topBottomFilter, uniqueDisplayFormats]);

    return (
        <Stack gap={2}>
            <Box display="flex" gap={2} alignItems="center">
                <FormControl>
                    {!topBottomFilter && (
                        <InputLabel
                            sx={{
                                transform: 'translateY(0.6rem)',
                                marginLeft: '1rem',
                                '&.MuiInputLabel-shrink': {
                                    transform: 'translateY(0.6rem)',
                                    marginLeft: '1rem',
                                },
                            }}
                        >
                            {t('Modules.Main.Campaigns.Overview.dsp.value')}
                        </InputLabel>
                    )}
                    <Select
                        value={topBottomFilter}
                        onChange={handleTopBottomFilterChange}
                        displayEmpty
                        size="small"
                        IconComponent={topBottomFilter ? () => null : undefined}
                        sx={{ minWidth: '15rem' }}
                    >
                        {topBottomFilter !== null || topBottomFilter === 'none' ? (
                            <MenuItem value="none">
                                {t('Modules.Main.Campaigns.Overview.dsp.value')}
                            </MenuItem>
                        ) : null}
                        <MenuItem value="top">
                            {t('Modules.Main.Campaigns.Overview.dsp.top')}
                        </MenuItem>
                        <MenuItem value="bottom">
                            {t('Modules.Main.Campaigns.Overview.dsp.bottom')}
                        </MenuItem>
                    </Select>
                </FormControl>
                {topBottomFilter !== 'none' && topBottomFilter != null && (
                    <TextField
                        type="number"
                        value={topBottomCount}
                        onChange={handleTopBottomCountChange}
                        size="small"
                        InputProps={{ inputProps: { min: 1 } }}
                        label={t('Modules.Main.Campaigns.Overview.dsp.count')}
                        sx={{ flexShrink: 0 }}
                    />
                )}
                <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                    <CheckboxSelect
                        value={filters['Display Owner']}
                        onChange={selectedValues =>
                            handleFilterChange(
                                { target: { value: selectedValues } },
                                'Display Owner',
                            )
                        }
                        items={uniqueDisplayOwners}
                        itemCounts={displayOwnerCounts}
                        label={t('Modules.Main.Campaigns.Overview.dsp.displayOwner')}
                    />

                    <CheckboxSelect
                        value={filters['Display Format']}
                        onChange={selectedValues =>
                            handleFilterChange(
                                { target: { value: selectedValues } },
                                'Display Format',
                            )
                        }
                        items={uniqueDisplayFormats}
                        itemCounts={displayFormatCounts}
                        label={t('Modules.Main.Campaigns.Overview.dsp.displayFormat')}
                    />
                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                            {Object.keys(displayedHeaders).map((header, index) => (
                                <TableCell
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    sortDirection={orderBy === header ? order : 'asc'}
                                >
                                    <TableSortLabel
                                        active={orderBy === header}
                                        direction={orderBy === header ? order : 'asc'}
                                        onClick={() => handleRequestSort(header)}
                                        sx={{
                                            color: theme.palette.custom.contentBgColor,
                                            '&.MuiTableSortLabel-root:hover': {
                                                color: theme.palette.custom.contentBgColor,
                                            },
                                            '&.Mui-active': {
                                                color: theme.palette.custom.contentBgColor,
                                            },
                                            '& .MuiTableSortLabel-icon': {
                                                color: `${theme.palette.custom.contentBgColor} !important`,
                                            },
                                        }}
                                    >
                                        <Typography color={theme.palette.custom.contentBgColor}>
                                            {headers[header]}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRow key={rowIndex}>
                                    <TableCell>{row['Display Owner']}</TableCell>
                                    <TableCell>{row['Display Format']}</TableCell>
                                    <TableCell>{Math.round(row.Plays).toLocaleString()}</TableCell>
                                    <TableCell>
                                        {Math.round(row['DSP Impressions']).toLocaleString()}
                                    </TableCell>
                                    {type === 'geopath' && (
                                        <TableCell>
                                            {Math.round(
                                                row['Geopath Impressions'],
                                            ).toLocaleString()}
                                        </TableCell>
                                    )}
                                    {type === 'geopath' && (
                                        <TableCell>
                                            <Typography
                                                color={
                                                    Math.round(parseFloat(row.VAR)) === 0
                                                        ? 'black'
                                                        : Math.round(parseFloat(row.VAR)) > 0
                                                        ? theme.palette.success.main
                                                        : theme.palette.error.main
                                                }
                                            >
                                                {Math.round(parseFloat(row.VAR)).toLocaleString()}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {parseFloat(row['DSP IM']).toFixed(2).toLocaleString()}
                                    </TableCell>
                                    {type === 'geopath' && (
                                        <TableCell>
                                            {parseFloat(row['Geopath IM'])
                                                .toFixed(2)
                                                .toLocaleString()}
                                        </TableCell>
                                    )}
                                    {type === 'vistar' && (
                                        <TableCell>
                                            {parseFloat(row['Average IM'])
                                                .toFixed(2)
                                                .toLocaleString()}
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Typography
                                            color={
                                                parseFloat(row['IM VAR']) === 0
                                                    ? 'black'
                                                    : parseFloat(row['IM VAR']) > 0
                                                    ? theme.palette.success.main
                                                    : theme.palette.error.main
                                            }
                                        >
                                            {String(
                                                parseFloat(row['IM VAR'])
                                                    .toFixed(2)
                                                    .toLocaleString(),
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={determineColor(normalizeValue(row), theme)}
                                        >
                                            {`${parseFloat(row['% VAR']).toFixed(0)} %`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Stack>
    );
};

const CheckboxSelect = ({ value, onChange, items, itemCounts, label }) => {
    const handleChange = event => {
        const {
            target: { value: selectedValues },
        } = event;
        onChange(selectedValues);
    };
    const handleClear = () => {
        onChange([]);
    };

    return (
        <FormControl
            sx={{
                minWidth: '15rem',
                maxWidth: '30rem',
                '& .MuiInputLabel-root': { marginTop: '-0.4rem' },
            }}
        >
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
                size="small"
                labelId={`${label}-label`}
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={selected => selected.join(', ')}
                IconComponent={value.length !== 0 ? () => null : undefined}
                MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 'fit-content' } } }}
            >
                {items.map(item => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={value.indexOf(item) > -1} />
                        <ListItemText primary={`${item} (${itemCounts[item] || 0})`} />
                    </MenuItem>
                ))}
            </Select>
            {value.length > 0 && (
                <IconButton
                    onClick={handleClear}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        padding: 0,
                        color: 'text.secondary',
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </FormControl>
    );
};

const DspReporting = ({ id }) => {
    const tabs = useMemo<TabWrapperSettings['tabs']>(() => {
        const theTabs = [
            {
                title: 'Modules.Main.Campaigns.Overview.dsp.vistar',
                content: <ReportingTable type="vistar" id={id} />,
                tabPanelStyling: { py: 0 },
                className: 'vistar-Table',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.dsp.geopath',
                content: <ReportingTable type="geopath" id={id} />,
                tabPanelStyling: { py: 0 },
                className: 'geopath-Table',
            },
        ];

        return theTabs;
    }, [id]);

    // return <TabsWrapper tabs={tabs} type="secondary" />;
    return <TabsWrapper tabs={tabs} type="secondary" />;
    // return <ReportingTable type="vistar" id={id} />;
};

export default DspReporting;
