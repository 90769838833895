import urlcat from 'urlcat';
import { AxiosRequestConfig } from 'axios';
import {
    PCAAnalyticsReportRequestPayload,
    PCAAnalyticsReportResponse,
    PCACampaignFramesRequestPayload,
    PCAFrameDetails,
    PCAMapReportRequestPayload,
    PCAMapReportResponse,
    PCAReportByFieldDailyResponse,
    PCAReportByFieldHourlyResponse,
    PCAReportByFieldResponse,
    PCAReportCountField,
    PCAReportDailyResponse,
    PCAReportEnergyByFieldResponse,
    PCAReportEnvironment,
    PCAReportHourlyResponse,
    PCAReportRequestPayload,
} from '../Types/Reports';
import { generateArrayQSParams } from '../Lib';
import { ModuleConstructorProps } from '../Types';

const Reports = ({ endpoints, httpClient, baseUrl }: ModuleConstructorProps) => ({
    pcaReport: (
        {
            campaignId,
            startDate,
            endDate,
            metric,
            campaignStats = false,
            timeFrame,
            field,
            countFields = [
                PCAReportCountField.InSchedule,
                PCAReportCountField.OutSchedule,
                PCAReportCountField.UnBooked,
            ],
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
        }: PCAReportRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start: startDate,
            end: endDate,
            metric,
            environment,
            report: `${field ?? ''}${
                timeFrame != null
                    ? field == null
                        ? String(timeFrame).toLowerCase()
                        : timeFrame
                    : ''
            }`,
        };

        if (campaignStats) {
            // @ts-ignore
            data.campaignStats = true;
        }

        return httpClient.post<
            | PCAReportHourlyResponse
            | PCAReportDailyResponse
            | PCAReportByFieldResponse
            | PCAReportByFieldDailyResponse
            | PCAReportByFieldHourlyResponse
        >(
            `${urlcat(endpoints.reports.pca.endpoint, data)}${generateArrayQSParams(
                countFields,
                'count_fields',
                '&',
            )}`,
            { filters: fieldFilters },
            config,
        );
    },
    pcaMapReport: (
        {
            campaignId,
            startDate,
            endDate,
            metric,
            campaignStats = false,
            countFields = [
                PCAReportCountField.InSchedule,
                PCAReportCountField.OutSchedule,
                PCAReportCountField.UnBooked,
            ],
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
        }: PCAMapReportRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start: startDate,
            end: endDate,
            metric,
            environment,
        };

        if (campaignStats) {
            // @ts-ignore
            data.campaignStats = true;
        }

        return httpClient.post<PCAMapReportResponse>(
            `${urlcat(endpoints.reports.pca.mapEndpoint, data)}${generateArrayQSParams(
                countFields,
                'count_fields',
                '&',
            )}`,
            { filters: fieldFilters },
            config,
        );
    },
    pcaAnalyticsReport: (
        {
            campaignId,
            startDate,
            endDate,
            countFields = [
                PCAReportCountField.InSchedule,
                PCAReportCountField.OutSchedule,
                PCAReportCountField.UnBooked,
            ],
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
        }: PCAAnalyticsReportRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start: startDate,
            end: endDate,
            environment,
        };

        return httpClient.post<PCAAnalyticsReportResponse>(
            `${urlcat(endpoints.reports.pca.analyticsEndpoint, data)}${generateArrayQSParams(
                countFields,
                'count_fields',
                '&',
            )}`,
            { filters: fieldFilters },
            config,
        );
    },
    pcaCampaignFrames: (
        {
            campaignId,
            metric,
            fieldFilters = {},
            startDate,
            endDate,
            environment = PCAReportEnvironment.Live,
        }: PCACampaignFramesRequestPayload,
        config?: AxiosRequestConfig,
    ) =>
        httpClient.post<PCAFrameDetails[]>(
            `${urlcat(endpoints.reports.pca.campaignFramesEndpoint, {
                id: campaignId,
                metric,
                start: startDate,
                end: endDate,
                environment,
            })}`,
            { filters: fieldFilters },
            config,
        ),
    pcaRunReport: ({ id, consumerType = 'full', environment }, config?: AxiosRequestConfig) =>
        httpClient.post(
            `${urlcat(endpoints.reports.pca.run, { id })}?consumer=${consumerType}`,
            { environment },
            config,
        ),
    pcaDeleteReport: ({ id, environment }, config?: AxiosRequestConfig) =>
        httpClient.post(`${urlcat(endpoints.reports.pca.delete, { id })}`, { environment }, config),
    calculateTargetMultiplier: ({ id, target_id }, config?: AxiosRequestConfig) =>
        httpClient.post(
            `${urlcat(endpoints.reports.pca.targetMultiplier, { id })}`,
            { target_id },
            config,
        ),
    publishReport: ({ id }, config?: AxiosRequestConfig) =>
        httpClient.post(`${urlcat(endpoints.reports.pca.publish, { id })}`, config),

    pcaReportEnergy: (
        {
            campaignId,
            startDate,
            endDate,
            campaignStats = false,
            field,
            fieldFilters = {},
            environment = PCAReportEnvironment.Live,
        }: PCAReportRequestPayload,
        config?: AxiosRequestConfig,
    ) => {
        const data = {
            id: campaignId,
            start: startDate,
            end: endDate,
            field,
            environment,
        };

        if (campaignStats) {
            // @ts-ignore
            data.campaignStats = true;
        }

        return httpClient.post<PCAReportEnergyByFieldResponse>(
            `${urlcat(endpoints.reports.pca.energyEndpoint, data)}`,
            { filters: fieldFilters },
            config,
        );
    },
    impressionMultipliers: ({ id }, config?: AxiosRequestConfig) =>
        httpClient.get(`${urlcat(endpoints.reports.pca.impressionMultipliers, { id })}`, config),
    geopathImpressionMultipliers: ({ id }, config?: AxiosRequestConfig) =>
        httpClient.get(
            `${urlcat(endpoints.reports.pca.geopathImpressionMultipliers, { id })}`,
            config,
        ),
});

const generateFieldFilterQueryString = (
    fieldFilters: PCAReportRequestPayload['fieldFilters'],
    firstAsQuestionMark = false,
) =>
    Object.keys(fieldFilters)
        .reduce((acc, curr, index) => {
            acc.push(
                generateArrayQSParams(
                    fieldFilters[curr],
                    `filters.${curr}`,
                    index === 0 && firstAsQuestionMark ? '?' : '&',
                ),
            );
            return acc;
        }, [] as string[])
        .join('');

export default Reports;
